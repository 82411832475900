import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { View, ScrollView } from 'react-native'
import _ from 'lodash'

import { getKey, getLabel, SelectWithData } from './SelectWithData'
import spacing from '../../styles/spacing'
import MSFESButton from '../MSFESButton'
import MSFESChip from '../MSFESChip'

export const SelectMultipleFields = React.forwardRef((props, ref) => {
  const { changeHandler, fieldsSupplied } = props

  const fieldListRoot = useSelector((state) => {
    const entity = props.data?.entity
    return state.entityTypes.data.find(
      (entityType) =>
        entity &&
        (entityType.id === entity?.id || entityType.name === entity?.name)
    )
  })

  const fieldsUnsorted = fieldsSupplied
    ? fieldsSupplied
    : _.get(fieldListRoot, 'fields', [])

  const fields = _.sortBy(fieldsUnsorted, (f) =>
    _.get(f, 'field_data.sort_order', 999)
  )

  const [statefulValue, setStatefulValue] = useState(props.value ?? [])

  const updateValue = (newValue) => {
    setStatefulValue(newValue)
    changeHandler(newValue)
  }

  const removeField = (value) => {
    const localValue = statefulValue.filter(
      (v) => getKey(v, null, keyField) !== getKey(value, null, keyField)
    )

    updateValue(localValue)
  }

  const keyField = 'field_data.property'
  const labelField = 'field_data.title'
  const valueField = 'field_data.title'
  return (
    <View style={{ flex: 1, flexDirection: 'row', maxWidth: 400 }}>
      <ScrollView
        horizontal
        style={{ flex: 1, flexDirection: 'row', marginHorizontal: spacing.m2 }}
      >
        {statefulValue &&
          statefulValue.map((valueOption, index) => {
            return (
              <MSFESChip
                key={index}
                onClose={() => removeField(valueOption)}
                style={{ marginTop: spacing.m1 }}
              >
                {getLabel(valueOption, labelField)}
              </MSFESChip>
            )
          })}
      </ScrollView>
      <View style={{ justifyContent: 'center' }}>
        <SelectWithData
          dataRows={fields}
          value={''}
          placeholder={''}
          selectComponent={({ onPress }) => (
            <MSFESButton
              icon={{ type: 'font-awesome', name: 'plus' }}
              type="add"
              onPress={onPress}
            ></MSFESButton>
          )}
          labelField={labelField}
          keyField={keyField}
          valueField={valueField}
          onChangeText={(value) => {
            const localValue = [...statefulValue]
            if (
              localValue.find(
                (v) =>
                  getKey(v, null, keyField) === getKey(value, null, keyField)
              )
            ) {
              // remove
            } else {
              localValue.push(value)
            }
            updateValue(localValue)
          }}
        />
      </View>
    </View>
  )
})
