// libraries
import React, { useMemo, useState } from 'react'
import { View, Text, ScrollView, StyleSheet } from 'react-native'
import { withTheme, ListItem } from 'react-native-elements'
import { useSelector } from 'react-redux'
import { snakeCase } from 'snake-case'
import { capitalCase } from 'capital-case'
import _ from 'lodash'
import { Overlay } from 'react-native-elements'

// internal libraries
import { supportedFieldTypes } from '../libraries/supportedFieldTypes'
// styles

//actions

// components
import {
  SelectWithData,
  SelectField,
  SelectIcon
} from './fields/SelectWithData'
import { WrappedSwitch } from './EntityField'
import MSFESInput from './MSFESInput'
import { ContainerFieldsPicker } from './ContainerFieldsPicker'
import { List } from 'react-native-paper'
import MSFESButton from './MSFESButton'
import { TextArrayField } from './fields/TextArrayField'
import {
  allKey,
  globalAdminRole,
  MultipleRoleSelector
} from './fields/MultipleRolePicker'
import MSFESLabel from './MSFESLabel'
import RichTextContainer from './RichTextContainer'
import { Dimensions } from 'react-native'
import { CurrentlyNoLabelOnly } from './CurrentlyNoItems'
import { Platform } from 'react-native'
import OverlayHeader from './OverlayHeader'
import MSFESAccordion from './MSFESAccordion'
import YesNoCancelDialog from './YesNoCancelDialog'
import { DynamicTextFields } from './fields/DynamicTextFields'
import QueryEditorField from './fields/QueryEditorField'
import FieldLinkTo from './fields/FieldLinkTo'
import { TrueFalseQuiz } from './fields/TrueFalseQuiz'
import MSFESHeading from './MSFESHeading'
import RadioWithData from './fields/RadioWithData'
import { viewMode } from '../screens/EntityAddComponent'
import { useTenantOrGlobalAdmin } from '../hooks/useTenantOrGlobalAdmin'
import { SelectListMultiple } from './fields/SelectListMultiple'
import spacing from '../styles/spacing'
import { SelectList } from './fields/SelectList'
import ColorPickerField from './fields/ColorPickerField'
import { CURRENT_USER_ENTITY_TYPE } from '../libraries/supportedEntityTypeProperties'

import { DateTime } from 'luxon'
const propertyExists = (newPropertyName, entity) => {
  const newPropertyNameLower = newPropertyName.toLowerCase()
  const fieldsThatMatch = entity.data.fields.filter(
    (f) =>
      _.get(f, 'field_data.property', '').toLowerCase() === newPropertyNameLower
  )

  return fieldsThatMatch.length > 0
}
export const EntityTypeField = (props) => {
  const {
    field,
    fieldChanged,
    entity,
    toggleExpanded,
    isExpanded,
    deleteField,
    onLongPress = null,
    pages = 1,
    mode,
    setActivePage = null,
    userIsLocalAndEditingGlobal
  } = props

  const {
    isGlobalAdmin,
    isCurrentlyGlobalAdmin,
    isInternalRole
  } = useTenantOrGlobalAdmin()

  const userIsEditingOwnField = userIsLocalAndEditingGlobal && field.tenant_id
  const isNumberRangeTypes = ['number-slider', 'star-rating', 'number-counter']
  const includedPropsTenant = { editable: !isCurrentlyGlobalAdmin }
  const instanceFieldStyle = isCurrentlyGlobalAdmin ? { opacity: 0.5 } : {} // for instance fields, make them appear disabled when we are a global user.
  const nonInstanceFieldStyle =
    !isCurrentlyGlobalAdmin &&
    entity.is_global &&
    !field.tenant_id &&
    !entity.tenant_id
      ? { opacity: 0.65 }
      : {} // for instance fields, make them appear disabled when we are a global user.

  const includedProps = {
    editable: !!(
      (mode !== viewMode && !userIsLocalAndEditingGlobal) ||
      userIsEditingOwnField
    ),
    style: nonInstanceFieldStyle
  }

  const [deleteFieldPending, setDeleteFieldPending] = useState(null)

  const yesDeleteField = () => {
    deleteField(field)
  }

  const noDeleteField = () => {
    setDeleteFieldPending(null)
  }

  const onDeleteFieldPressed = () => {
    setDeleteFieldPending(true)
  }
  const entityTypes = useSelector((state) =>
    _.get(state, 'entityTypes.dataLite.data', null)
  )

  const changeTitleHandler = (newTitle) => {
    if (!field.id) {
      // we're working on a new field.

      let newPropertyName = newTitle ? snakeCase(newTitle) : ''

      let newPropertyNameSuggested = newPropertyName
      let iterator = 0
      while (propertyExists(newPropertyNameSuggested, entity)) {
        iterator += 1
        newPropertyNameSuggested = newPropertyName + iterator
      }
      changeHandlerDebounced({
        property: newPropertyNameSuggested,
        title: newTitle
      })
    } else {
      changeHandlerDebounced({ title: newTitle })
    }
  }

  const updateOrRemoveValidationKey = (key, value) => {
    if (value === '') {
      const newField = { ...field }
      _.unset(newField, `field_data.${key}`)
      fieldChanged(newField)
    } else {
      changeHandler(key, value)
    }
  }

  const changeHandler = (property, newValue = undefined) => {
    const newField = { ...field }

    _.set(newField, 'modified_at', DateTime.now().toISO())
    if (typeof newValue === 'undefined') {
      // object style
      _.each(property, (value, key) => {
        _.set(newField, `field_data.${key}`, value)
      })
      fieldChanged(newField)
    } else {
      _.set(newField, `field_data.${property}`, newValue)
      fieldChanged(newField)
    }
  }

  const changeHandlerDebounced = _.debounce(changeHandler, 200)

  const getErrorMessage = (field) => {
    if (props.errors) {
      return _.get(props.errors, field, null) //allows .* style
    }
  }

  const title = _.get(field, 'field_data.title', '(Unnamed field)')

  const titleEntry = _.get(field, 'field_data.title', '')
  const propertyEntry = _.get(field, 'field_data.property', '')
  const type = _.get(field, 'field_data.type', 'unknown')
  const childtype = _.get(field, 'field_data.childtype', 'unknown')

  const required = _.get(field, 'field_data.validation.required', false)

  const {
    on_create_only,
    usesLabelsAndValues,
    canUseAsGroup,
    canSelectFromLibrary
  } = useMemo(() => {
    return {
      on_create_only: _.get(
        field,
        'field_data.validation.on_create_only',
        false
      ),
      usesLabelsAndValues: _.get(
        field,
        'field_data.params.usesLabelsAndValues',
        true
      ),

      canUseAsGroup: _.get(field, 'field_data.params.canUseAsGroup', true),
      canSelectFromLibrary: _.get(
        field,
        'field_data.params.canSelectFromLibrary',
        true
      )
    }
  }, [field?.field_data])

  const acceptDecline = _.get(field, 'field_data.params.acceptDecline', true)

  const typeObject = supportedFieldTypes.find((field) => field.type === type)
  const canRequire = _.get(typeObject, 'canRequire', true)
  const canBeCreateOnly = _.get(typeObject, 'canBeCreateOnly', true)
  const isMatrix = _.get(typeObject, 'isMatrix', false)
  const flaggables = [
    'tableView',
    'tableSortable',
    'quickAddField',
    /*'adminOrGlobalAdminOnly', 'globalAdminOnly', */
    'hideFromReport'
  ]

  const usesSourceListTypes = [
    'select-list',
    'select-list-multiple',
    'view-list',
    'view-list-inline',
    'radio-list',
    'checkbox-multiselect'
  ]
  const canFilterFieldTypes = [
    'date-picker',
    'date-time-picker',
    'select-adhoc',
    'checkbox-multiselect-adhoc',
    'yes-no'
  ]

  const adhocListTypes = [
    'radio-adhoc',
    'select-adhoc',
    'checkbox-multiselect-adhoc',
    'radio-quiz',
    'checkbox-quiz'
  ]

  const quizFields = ['radio-quiz', 'checkbox-quiz', 'true-false-quiz']
  const maxHeightFieldTypes = [
    'description-rich-text',
    'terms-and-conditions',
    'rich-text-area'
  ]

  const containerFieldTypes = [
    'container-row',
    'container-group',
    'quiz-question-group',
    'container-tile',
    'container-button'
  ]
  const canHaveFormulaAppliedFields = supportedFieldTypes
    .filter((f) => _.get(f, 'canHaveFormulaApplied', false))
    .map((f) => f.type)

  const multipleSelectListTypes = ['checkbox-multiselect-adhoc']

  const hasErrors = !!Object.keys(props.errors).length

  const maxOverlayHeight =
    Platform.OS === 'web' ? Dimensions.get('window').height * 0.9 : 600

  const typeForSubFields = isMatrix ? childtype : type

  const isQuizField = useMemo(() => {
    return quizFields.includes(typeForSubFields)
  }, [typeForSubFields])

  const isInsideQuiz = useMemo(() => {
    return props.entity?.data?.object_data?.is_quiz
  }, [props.entity?.data?.object_data?.is_quiz])

  const nameForType = useMemo(() => {
    try {
      return supportedFieldTypes.find((f) => f.type === typeForSubFields).name
    } catch (e) {
      return typeForSubFields
    }
  }, [typeForSubFields])

  const isInsideFieldName = useMemo(() => {
    const { entity } = props
    const fieldsContainingFields = entity?.data?.fields.filter(
      (f) => f.field_data?.params?.fieldList?.length > 0
    )

    for (let i = 0; i < fieldsContainingFields.length; i++) {
      const match = fieldsContainingFields[
        i
      ].field_data?.params?.fieldList?.find(
        (f) => f.offline_id === field.offline_id
      )
      if (match) {
        return fieldsContainingFields[i].field_data?.title
      }
    }
  }, [entity?.data?.fields])

  const configurables = useMemo(() => {
    let configurables = []

    const rollupTypes = [
      // {id: "average", label: "Average", value: "average"},
      { id: 'sum', label: 'Sum', value: 'sum' },
      { id: 'count', label: 'Count', value: 'count' },
      { id: 'any', label: 'Any are Truthy', value: 'any' },
      {
        id: 'comma_delimited',
        label: 'Join - Comma Delimited',
        value: 'comma_delimited'
      },
      { id: 'all', label: 'All are Truthy', value: 'all' }
    ]

    const hasBottomBorder = {
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderColor: '#efefef'
    }

    const params = _.get(field, 'field_data.params', null)

    if (isMatrix) {
      configurables.push(
        <MSFESInput
          key="childFieldType"
          InputComponent={SelectWithData}
          label={'Matrix Child Field Type'}
          dataRows={supportedFieldTypes.filter((f) => !f.isMatrix)}
          onChangeText={(newType) => changeHandler('childtype', newType.type)}
          errorMessage={getErrorMessage('childtype')}
          placeholder={`(Select ${title} child type)`}
          value={childtype}
          valueField="type"
          labelField="name"
          keyField="type"
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          InputComponent={TextArrayField}
          key="matrixProperties"
          label={'Matrix Properties'}
          onChangeText={(newType) => changeHandler('matrixProperties', newType)}
          errorMessage={getErrorMessage('matrixProperties')}
          value={_.get(field, 'field_data.matrixProperties', null)}
          {...includedProps}
        />
      )
    }

    if (typeForSubFields === 'container-row') {
      configurables.push(
        <MSFESInput
          key="container-fields"
          label={'Row Header is only Header'}
          title={'Row Header is only Header'}
          helpText={
            'Hides header for each field in this row, showing a row header instead.'
          }
          InputComponent={WrappedSwitch}
          supportsInputShading={false}
          onValueChange={(newValue) => {
            changeHandler('params.useRowHeader', newValue)
          }}
          field={field}
          errorMessage={getErrorMessage('params.useRowHeader')}
          value={_.get(field, 'field_data.params.useRowHeader', null)}
          {...includedProps}
        />
      )
    }

    if (containerFieldTypes.includes(typeForSubFields)) {
      configurables.push(
        <MSFESInput
          key="container-fields-list"
          InputComponent={ContainerFieldsPicker}
          label={'Fields'}
          title={'Fields'}
          onChangeText={(newType) => {
            changeHandler('params.fieldList', newType)
          }}
          fields={entity.data.fields}
          field={field}
          errorMessage={getErrorMessage('params.fieldList')}
          value={_.get(field, 'field_data.params.fieldList', null)}
          {...includedProps}
        />
      )
    }

    if (['number-entry', ...isNumberRangeTypes].includes(typeForSubFields)) {
      if (typeForSubFields === 'number-entry') {
        configurables.push(
          <MSFESInput
            key="isNumberRange"
            InputComponent={WrappedSwitch}
            supportsInputShading={false}
            label={'Is Number Range'}
            title={'Is Number Range'}
            onValueChange={(newValue) => {
              changeHandler('params.isNumberRange', newValue)
            }}
            errorMessage={getErrorMessage('params.fieldLisNumberRangeist')}
            value={_.get(field, 'field_data.params.isNumberRange', null)}
            {...includedProps}
          />
        )
      }

      if (
        params?.isNumberRange ||
        isNumberRangeTypes.includes(typeForSubFields)
      ) {
        configurables.push(
          <MSFESInput
            key="min"
            label={'Minimum Value'}
            title={'Minimum'}
            keyboardType="numeric"
            onChangeText={(min) => {
              updateOrRemoveValidationKey('validation.min', min)
            }}
            value={_.get(field, 'field_data.validation.min')}
            {...includedProps}
          />
        )
        configurables.push(
          <MSFESInput
            key="max"
            keyboardType="numeric"
            label={'Maximum Value'}
            title={'Maximum'}
            onChangeText={(max) => {
              updateOrRemoveValidationKey('validation.max', max)
            }}
            value={_.get(field, 'field_data.validation.max')}
            {...includedProps}
          />
        )
      }

      if (
        !params?.isNumberRange &&
        !isNumberRangeTypes.includes(typeForSubFields)
      ) {
        configurables.push(
          <MSFESInput
            key="digits"
            label={'Digits Required'}
            title={'Digits Required'}
            helpText={
              'Enter the amount of digits required for this field (e.g. 4 for a year, or 5 for a ZIP code).'
            }
            keyboardType="numeric"
            onChangeText={(min) => {
              updateOrRemoveValidationKey('validation.digits', min)
            }}
            value={_.get(field, 'field_data.validation.digits')}
            {...includedProps}
          />
        )
      }

      if (typeForSubFields === 'number-entry')
        configurables.push(
          <MSFESInput
            key="whole-numbers"
            InputComponent={WrappedSwitch}
            supportsInputShading={false}
            label={'Whole numbers only'}
            title={'Whole numbers only'}
            onValueChange={(value) => {
              updateOrRemoveValidationKey('validation.integer', value)
            }}
            value={_.get(field, 'field_data.validation.integer')}
            {...includedProps}
          />
        )
    }

    if (typeForSubFields === 'select-personnel') {
      configurables.push(
        <MSFESInput
          key="locked-creator"
          InputComponent={WrappedSwitch}
          supportsInputShading={false}
          label={'Locked to creator'}
          data={{
            entity: {
              name: _.get(field, 'field_data.params.lockedToCreator', null)
            }
          }}
          errorMessage={getErrorMessage('params.lockedToCreator')}
          onValueChange={() => {
            const newState = !_.get(
              field,
              'field_data.params.lockedToCreator',
              null
            )
            changeHandler(`params.lockedToCreator`, newState)
          }}
          value={_.get(field, 'field_data.params.lockedToCreator')}
          {...includedProps}
        />
      )
    }

    if (
      typeForSubFields === 'rich-text-area' ||
      typeForSubFields == 'description-rich-text'
    ) {
      configurables.push(
        <MSFESInput
          key="displayInline"
          InputComponent={WrappedSwitch}
          supportsInputShading={false}
          label={'Display Inline'}
          title={'Display Inline'}
          onValueChange={(newValue) => {
            changeHandler('params.displayInline', newValue)
          }}
          errorMessage={getErrorMessage('params.displayInline')}
          value={_.get(field, 'field_data.params.displayInline', null)}
          {...includedProps}
        />
      )
    }

    if (typeForSubFields === 'single-file') {
      configurables.push(
        <MSFESInput
          key="file-type"
          InputComponent={SelectWithData}
          label={'Type'}
          dataRows={[
            {
              type: 'image',
              name: 'Image'
            },
            {
              type: 'document',
              name: 'Document'
            },
            {
              type: 'audiovideo',
              name: 'Audio/Video'
            }
          ]}
          onChangeText={(newType) =>
            changeHandler('params.fileType', newType.type)
          }
          errorMessage={getErrorMessage('params.fileType')}
          placeholder={`(Select file type)`}
          value={_.get(field, 'field_data.params.fileType')}
          valueField="type"
          labelField="name"
          keyField="type"
          {...includedProps}
        />
      )
      configurables.push(
        <MSFESInput
          InputComponent={WrappedSwitch}
          supportsInputShading={false}
          key="allow-multiple"
          label={'Allow Multiple'}
          onValueChange={() => {
            const newValue = !(
              field.field_data?.params?.allowMultipleUploads ?? false
            )
            changeHandler('params.allowMultipleUploads', newValue)
          }}
          value={field.field_data?.params?.allowMultipleUploads ?? false}
          errorMessage={getErrorMessage('params.allowMultipleUploads')}
          {...includedProps}
        />
      )
      configurables.push(
        <MSFESInput
          key="select-from-library"
          InputComponent={WrappedSwitch}
          supportsInputShading={false}
          label={'Can Select From Library'}
          onValueChange={() => {
            const newValue = !canSelectFromLibrary
            changeHandler('params.canSelectFromLibrary', newValue)
          }}
          value={canSelectFromLibrary}
          errorMessage={getErrorMessage('params.canSelectFromLibrary')}
          {...includedProps}
        />
      )
    }

    if (['widget-entity'].includes(typeForSubFields)) {
      configurables.push(
        <MSFESInput
          key="select-widget"
          InputComponent={SelectList}
          label={'Widget Source'}
          data={{
            entity: {},
            field: {
              field_data: {
                params: {
                  sourceName: 'widget_entity'
                }
              }
            }
          }}
          onChangeText={(newType) => {
            changeHandler('params.widgetSource', newType)
          }}
          errorMessage={getErrorMessage('params.widgetSource')}
          defaultValue={field?.field_data?.params?.widgetSource}
          placeholder={`(Select source widget)`}
          {...includedProps}
        />
      )
    }
    if (usesSourceListTypes.includes(typeForSubFields)) {
      configurables.push(
        <MSFESInput
          key="list-source"
          InputComponent={SelectWithData}
          label={'List Source'}
          dataRows={entityTypes}
          onChangeText={(newType) => {
            changeHandler('params.sourceName', newType.name)
          }}
          errorMessage={getErrorMessage('params.sourceName')}
          value={_.get(field, 'field_data.params.sourceName', null)}
          placeholder={`(Select source list)`}
          valueField="name"
          labelField="label"
          keyField="name"
          {...includedProps}
        />
      )

      if (['select-list'].includes(typeForSubFields)) {
        configurables.push(
          <MSFESInput
            InputComponent={WrappedSwitch}
            supportsInputShading={false}
            key="load-future"
            label={'Load future entities from calendar'}
            helpText="Check the calendar for future entries for this dropdown"
            onValueChange={() => {
              const newValue = !field?.field_data?.params?.loadFutureEntities
              changeHandler('params.loadFutureEntities', newValue)
            }}
            errorMessage={getErrorMessage('params.loadFutureEntities')}
            value={field?.field_data?.params?.loadFutureEntities ?? false}
            {...includedProps}
          />
        )
      }
      configurables.push(
        <MSFESInput
          InputComponent={WrappedSwitch}
          supportsInputShading={false}
          key="can-group"
          label={'Can Group with this list'}
          helpText="Allows this field to be used throughout the app for grouping (e.g. when selecting a group of assignees)."
          onValueChange={() => {
            const newValue = !canUseAsGroup
            changeHandler('params.canUseAsGroup', newValue)
          }}
          errorMessage={getErrorMessage('params.canUseAsGroup')}
          value={_.get(field, 'field_data.params.canUseAsGroup', true)}
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          InputComponent={QueryEditorField}
          key="options-filter"
          label={'Options filter'}
          title={'Options filter'}
          helpText={'Options must evaluate to true to show in the picker.'}
          changeHandler={(value) => {
            changeHandler('params.optionsFilter', value)
          }}
          data={{
            entity: {
              entity_type_target: {
                name: _.get(field, 'field_data.params.sourceName', null)
              }
            }
          }}
          additionalFieldsForQueryEditor={entity?.data?.fields.map((f) => ({
            title: 'Field: ' + f.field_data?.title,
            value: `field.${f.field_data?.property}` /* 'field.xyz' is resolved as the value in this field by evaluateRule. */
          }))}
          defaultValue={_.get(field, 'field_data.params.optionsFilter')}
          style={hasBottomBorder}
          {...includedProps}
        />
      )
    }

    if (
      usesSourceListTypes.includes(typeForSubFields) ||
      canFilterFieldTypes.includes(typeForSubFields)
    ) {
      configurables.push(
        <MSFESInput
          InputComponent={WrappedSwitch}
          supportsInputShading={false}
          key="can-filter"
          label={'Can Filter with this list'}
          helpText="Allows this field to be used throughout the app for filtering"
          onValueChange={() => {
            const newValue = !(field.field_data?.params?.canUseAsFilter ?? true)
            changeHandler('params.canUseAsFilter', newValue)
          }}
          errorMessage={getErrorMessage('params.canUseAsFilter')}
          value={_.get(field, 'field_data.params.canUseAsFilter', true)}
          {...includedProps}
        />
      )
      configurables.push(
        <MSFESInput
          InputComponent={WrappedSwitch}
          supportsInputShading={false}
          key="filter-header"
          label={'Show Filter In Header'}
          helpText="Prominently shows this filter in the UI"
          onValueChange={() => {
            const newValue = !field.field_data?.params?.displayFilterInHeader
            changeHandler('params.displayFilterInHeader', newValue)
          }}
          errorMessage={getErrorMessage('params.displayFilterInHeader')}
          value={_.get(field, 'field_data.params.displayFilterInHeader', false)}
          {...includedProps}
        />
      )
    }

    if (multipleSelectListTypes.includes(typeForSubFields)) {
      configurables.push(
        <MSFESInput
          keyboardType="numeric"
          label={'Minimum Selections'}
          title={'Minimum Selections'}
          key="min-select"
          onValueChange={(min) => {
            updateOrRemoveValidationKey('validation.min', min)
          }}
          value={_.get(field, 'field_data.validation.min')}
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          keyboardType="numeric"
          label={'Maximum Selections'}
          title={'Maximum Selections'}
          key="max-select"
          onValueChange={(max) => {
            updateOrRemoveValidationKey('validation.max', max)
          }}
          value={_.get(field, 'field_data.validation.max')}
          {...includedProps}
        />
      )
    }

    if (isQuizField) {
      configurables.push(
        <MSFESInput
          keyboardType="numeric"
          key="quiz_correct_answer_marks"
          label={'Marks available'}
          errorMessage={getErrorMessage('params.quiz_correct_answer_marks')}
          onChangeText={(newText) => {
            changeHandlerDebounced('params.quiz_correct_answer_marks', newText)
          }}
          value={_.get(field, 'field_data.params.quiz_correct_answer_marks')}
          {...includedProps}
        />
      )
    }

    if (typeForSubFields === 'description-rich-text' || isQuizField) {
      configurables.push(
        <MSFESInput
          InputComponent={RichTextContainer}
          key="description"
          label={'Description'}
          errorMessage={getErrorMessage('params.descriptionText')}
          onChangeText={(newText) => {
            changeHandlerDebounced('params.descriptionText', newText)
          }}
          defaultValue={_.get(field, 'field_data.params.descriptionText')}
          {...includedProps}
        />
      )
    }

    if (typeForSubFields === 'true-false-quiz') {
      configurables.push(
        <MSFESInput
          InputComponent={TrueFalseQuiz}
          key="true-false-answer"
          label={'Correct Answer'}
          showDescription={false}
          keyField="offline_id"
          value={_.get(field, 'field_data.params.correctAnswer', null)}
          onChangeText={(correctAnswer) => {
            changeHandler('params.correctAnswer', correctAnswer)
          }}
          {...includedProps}
        />
      )
    }

    if (adhocListTypes.includes(typeForSubFields)) {
      configurables.push(
        <MSFESInput
          InputComponent={WrappedSwitch}
          supportsInputShading={false}
          label={'Uses labels and values'}
          key="use-labels-and-values"
          onValueChange={() => {
            const newValue = !usesLabelsAndValues
            changeHandler('params.usesLabelsAndValues', newValue)
          }}
          value={usesLabelsAndValues}
          errorMessage={getErrorMessage('params.usesLabelsAndValues')}
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          InputComponent={TextArrayField}
          canExportImport={true}
          isQuizField={isQuizField}
          usesLabelsAndValues={usesLabelsAndValues}
          label={'Option List'}
          key="option-list"
          onChangeText={(newOptions) =>
            changeHandler('params.options', newOptions)
          }
          errorMessage={getErrorMessage('params.options')}
          value={_.get(field, 'field_data.params.options', null)}
          style={hasBottomBorder}
          {...includedProps}
        />
      )
    }

    if (typeForSubFields === 'formula-result-rollup') {
      configurables.push(
        <MSFESInput
          InputComponent={SelectField}
          label={'Field holding relation to child'}
          key="select-related-field"
          fieldTypeFilter={['view-list', 'view-list-inline']}
          data={{
            entity: {
              name: entity.data.name
            }
          }}
          onChangeText={(newField) => {
            changeHandler('params.rollupRelationshipField', newField)
          }}
          value={_.get(
            field,
            'field_data.params.rollupRelationshipField',
            null
          )}
          helpText={
            "Select the field in this entity that connects to the child (usually a 'View List')"
          }
          placeholder={`Select field`}
          valueField="field_data.property"
          labelField="field_data.title"
          keyField="id"
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          InputComponent={DynamicTextFields}
          label={'Rollup Field'}
          data={{
            entity: (() => {
              //

              const fieldMatched = _.get(
                field,
                'field_data.params.rollupRelationshipField',
                null
              )

              const sourceField = entity.data.fields.find(
                (f) =>
                  f.field_data.property == fieldMatched?.field_data?.property
              )
              if (!sourceField) {
                return null
              }
              const sourceEntityTypeName =
                sourceField.field_data?.params?.sourceName

              const entityType = entityTypes.find(
                (e) => e.name === sourceEntityTypeName
              )

              const sourceEntityWrapper = {
                id: entityType?.id,
                name: entityType?.name
              }

              return sourceEntityWrapper
            })()
          }}
          disabled={
            !_.get(field, 'field_data.params.rollupRelationshipField', false)
          }
          key="rollup-relationship-field"
          title={'Rollup Field'}
          helpText={
            'This value will be extracted from the child entity. Select a field or enter a formula (to combine fields)'
          }
          changeHandler={(newFormula) => {
            changeHandlerDebounced('params.rollupFormula', newFormula)
          }}
          defaultValue={_.get(field, 'field_data.params.rollupFormula')}
          {...includedProps}
        />
      )

      // configurables.push(<MSFESInput
      //   InputComponent={SelectWithData}
      //   label={'Rollup Action'}

      //   disabled={!_.get(
      //     field,
      //     'field_data.params.rollupRelationshipField',
      //     false
      //   )}
      //   key="ollup-action"
      //   title={'Formula Input'}
      //   helpText={"Enter a formula to update this field contents."}
      //   changeHandler={(newFormula) => {
      //     changeHandlerDebounced('params.rollupFormula', newFormula)
      //   }}
      //   defaultValue={_.get(field, 'field_data.params.rollupFormula')}
      // />)

      configurables.push(
        <MSFESInput
          InputComponent={SelectWithData}
          dataRows={rollupTypes}
          label={'Rollup Type'}
          onChangeText={(newProperty) => {
            changeHandlerDebounced('params.rollupType', newProperty.id)
          }}
          labelField="label"
          errorMessage={getErrorMessage('field_data.params.rollupType')}
          placeholder={`Select Rollup Type`}
          value={_.get(field, 'field_data.params.rollupType', 1)}
          defaultValue={_.get(field, 'field_data.params.rollupType', 1)}
          {...includedProps}
        />
      )
    }
    /* 
    'select-field':
    For select field, we need to choose the field
    that houses the entity type
    to select a field from
    */
    if (
      ['view-list', 'view-list-inline', 'select-field'].includes(
        typeForSubFields
      )
    ) {
      configurables.push(
        <MSFESInput
          InputComponent={SelectField}
          label={'Related Field (In this entity)'}
          key="local-related-field"
          data={{
            entity: {
              name: entity.data.name
            }
          }}
          onChangeText={(newType) => {
            changeHandler('params.sourceEntityField', newType)
          }}
          helpText={'Select the field in the entity that matches to the child'}
          errorMessage={getErrorMessage('params.sourceEntityField')}
          placeholder={`(Select source field)`}
          value={_.get(field, 'field_data.params.sourceEntityField', null)}
          valueField="field_data.property"
          labelField="name"
          keyField="id"
          {...includedProps}
        />
      )
    }

    if (['view-list', 'view-list-inline'].includes(typeForSubFields)) {
      configurables.push(
        <MSFESInput
          InputComponent={SelectField}
          label={'Related Field (In Target)'}
          key="select-related-field"
          data={{
            entity: {
              name: _.get(field, 'field_data.params.sourceName', null)
            }
          }}
          onChangeText={(newType) => {
            changeHandler('params.targetEntityField', newType)
          }}
          helpText={'Select the field in the child that matches to this entity'}
          errorMessage={getErrorMessage('params.targetEntityField')}
          placeholder={`Select related field`}
          value={_.get(field, 'field_data.params.targetEntityField', null)}
          valueField="field_data.property"
          labelField="name"
          keyField="id"
          {...includedProps}
        />
      )
    }

    if (
      typeForSubFields === 'date-picker' ||
      typeForSubFields === 'date-time-picker'
    ) {
      configurables.push(
        <MSFESInput
          InputComponent={WrappedSwitch}
          supportsInputShading={false}
          label={'Locked to creation date'}
          key="locked-to-creation"
          data={{
            entity: {
              name: _.get(field, 'field_data.params.lockedToCreationDate', null)
            }
          }}
          errorMessage={getErrorMessage('params.lockedToCreationDate')}
          onValueChange={() => {
            const newState = !_.get(
              field,
              'field_data.params.lockedToCreationDate',
              null
            )
            changeHandler(`params.lockedToCreationDate`, newState)
          }}
          value={_.get(field, 'field_data.params.lockedToCreationDate')}
          {...includedProps}
        />
      )
    }

    if (typeForSubFields === 'heading') {
      configurables.push(
        <MSFESInput
          label={'Heading'}
          key="heading"
          errorMessage={getErrorMessage('params.headingText')}
          onChangeText={(newText) => {
            changeHandlerDebounced('params.headingText', newText)
          }}
          defaultValue={_.get(field, 'field_data.params.headingText')}
          {...includedProps}
        />
      )
    }

    if (typeForSubFields === 'text-label') {
      configurables.push(
        <MSFESInput
          label={'Label Text'}
          key="label"
          errorMessage={getErrorMessage('params.labelText')}
          onChangeText={(newText) => {
            changeHandlerDebounced('params.labelText', newText)
          }}
          defaultValue={_.get(field, 'field_data.params.labelText')}
          {...includedProps}
        />
      )
    }

    if (typeForSubFields === 'field-button') {
      configurables.push(
        <MSFESInput
          key="show_button_label"
          label={'Show Button Label'}
          title={'Show Button Label'}
          helpText={'Show the button label.'}
          onValueChange={(min) => {
            changeHandler('params.show_button_label', min)
          }}
          value={_.get(field, 'field_data.params.show_button_label', true)}
          horizontal
          supportsInputShading={false}
          InputComponent={WrappedSwitch}
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          horizontal
          InputComponent={SelectWithData}
          dataRows={[
            { id: 'primary', label: 'Primary', value: 'primary' },
            { id: 'outline', label: 'Outline', value: 'outline' }
          ]}
          label={'Button Style'}
          onChangeText={(newProperty) => {
            changeHandlerDebounced('params.button_style', newProperty.id)
          }}
          labelField="label"
          errorMessage={getErrorMessage('field_data.params.button_style')}
          placeholder={`Select button style`}
          value={_.get(field, 'field_data.params.button_style', 1)}
          defaultValue={_.get(field, 'field_data.params.button_style', 1)}
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          label={'Link To (DEPRECATED)'}
          key="linkTo"
          helpText={'DEPRECATED. Use Navigate To instead.'}
          errorMessage={getErrorMessage('params.buttonLinkTo')}
          onChangeText={(newText) => {
            changeHandlerDebounced('params.buttonLinkTo', newText)
          }}
          defaultValue={_.get(field, 'field_data.params.buttonLinkTo')}
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          label={'Navigate To'}
          key="navigateTo"
          InputComponent={FieldLinkTo}
          errorMessage={getErrorMessage('params.navigate_to')}
          onChangeText={(newValue) => {
            changeHandlerDebounced('params.navigate_to', newValue)
          }}
          defaultValue={_.get(field, 'field_data.params.navigate_to')}
          {...includedProps}
        />
      )
    }

    if (typeForSubFields === 'custom-functionality') {
      configurables.push(
        <MSFESInput
          InputComponent={SelectWithData}
          label={'Custom Functionality Type'}
          data={{ entity: { id: entity.data.id, name: entity.data.name } }}
          key="formula-input"
          dataRows={[
            {
              type: 'activation-status',
              name: 'Activation Status (Personnel)'
            },
            {
              type: 'random-entity-pull',
              name: 'Random Entity Pull'
            },
            {
              type: 'bib-view-report',
              name: 'View Background Check PDF'
            },
            {
              type: 'timesheet-punch-card',
              name: 'Time Sheet Punch Card (Time Sheet)'
            }
          ]}
          title={'Custom Functionality Type'}
          onChangeText={(newType) =>
            changeHandler('params.customFunctionalityType', newType.type)
          }
          errorMessage={getErrorMessage('params.customFunctionalityType')}
          value={_.get(field, 'field_data.params.customFunctionalityType')}
          valueField="type"
          labelField="name"
          keyField="type"
          {...includedProps}
        />
      )
    }
    if (typeForSubFields === 'terms-and-conditions') {
      configurables.push(
        <MSFESInput
          InputComponent={RichTextContainer}
          key="terms"
          label={'Terms'}
          title={'Terms'}
          errorMessage={getErrorMessage('params.termsText')}
          onChangeText={(newText) => {
            changeHandlerDebounced('params.termsText', newText)
          }}
          defaultValue={_.get(field, 'field_data.params.termsText')}
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          InputComponent={WrappedSwitch}
          supportsInputShading={false}
          label={'Decline Passes Validation'}
          key="decline-passes"
          onValueChange={() => {
            const newValue = !acceptDecline
            changeHandler('params.acceptDecline', newValue)
          }}
          helpText={
            'Enable this option to allow a user to decline the terms and submit the form. Disable this option to show the validation error if the terms are declined.'
          }
          value={acceptDecline}
          errorMessage={getErrorMessage('params.acceptDecline')}
          {...includedProps}
        />
      )
    }
    if (maxHeightFieldTypes.includes(typeForSubFields)) {
      configurables.push(
        <MSFESInput
          label={'Max Height'}
          key="max-height"
          title={'Max Height'}
          helpText={
            'If the height of the description text exceeds this height, it will be a scrollable area.'
          }
          keyboardType="numeric"
          onChangeText={(maxHeight) => {
            changeHandlerDebounced('params.maxHeight', maxHeight)
          }}
          defaultValue={_.get(field, 'field_data.params.maxHeight')}
          {...includedProps}
        />
      )
    }

    if (
      typeForSubFields == 'single-text-mask' ||
      typeForSubFields == 'single-text'
    ) {
      configurables.push(
        <MSFESInput
          InputComponent={SelectWithData}
          label={'Input Mask'}
          data={{ entity: { id: entity.data.id, name: entity.data.name } }}
          key="formula-input"
          dataRows={[
            {
              type: 'phone',
              name: 'Phone'
            },
            {
              type: 'dollar',
              name: 'Dollar'
            },
            {
              type: 'ssn',
              name: 'Social Security Number'
            },
            {
              type: 'custom',
              name: 'Custom'
            },
            {
              type: 'number',
              name: 'Number'
            }
          ]}
          title={'Input Mask'}
          onChangeText={(newType) =>
            changeHandler('params.inputMask', newType.type)
          }
          errorMessage={getErrorMessage('params.inputMask')}
          placeholder={`(Select input mask)`}
          value={_.get(field, 'field_data.params.inputMask')}
          valueField="type"
          labelField="name"
          keyField="type"
          {...includedProps}
        />
      )
    }
    if (
      typeForSubFields == 'single-text-mask' &&
      field?.field_data?.params?.inputMask === 'number'
    ) {
      configurables.push(
        <MSFESInput
          label={'Input Mask Suffix'}
          data={{ entity: { id: entity.data.id, name: entity.data.name } }}
          title={'Input Mask Suffix'}
          onChangeText={(newType) =>
            changeHandler('params.inputMaskSuffix', newType)
          }
          errorMessage={getErrorMessage('params.inputMaskSuffix')}
          placeholder={`Enter Suffix`}
          value={_.get(field, 'field_data.params.inputMaskSuffix')}
          {...includedProps}
        />
      )
      configurables.push(
        <MSFESInput
          label={'Input Mask Min Digits'}
          data={{ entity: { id: entity.data.id, name: entity.data.name } }}
          title={'Input Mask Min Digits'}
          onChangeText={(newType) =>
            changeHandler('params.inputMaskMinDigits', newType)
          }
          errorMessage={getErrorMessage('params.inputMaskMinDigits')}
          placeholder={`Enter Min Digits`}
          value={_.get(field, 'field_data.params.inputMaskMinDigits')}
          {...includedProps}
        />
      )
      configurables.push(
        <MSFESInput
          label={'Input Mask Max Digits'}
          data={{ entity: { id: entity.data.id, name: entity.data.name } }}
          title={'Input Mask Max Digits'}
          onChangeText={(newType) =>
            changeHandler('params.inputMaskMaxDigits', newType)
          }
          errorMessage={getErrorMessage('params.inputMaskMaxDigits')}
          placeholder={`Enter Max Digits`}
          value={_.get(field, 'field_data.params.inputMaskMaxDigits')}
          {...includedProps}
        />
      )
    }
    if (
      typeForSubFields == 'single-text-mask' &&
      field?.field_data?.params?.inputMask === 'custom'
    ) {
      configurables.push(
        <MSFESInput
          label={'Input Mask (JS)'}
          data={{ entity: { id: entity.data.id, name: entity.data.name } }}
          title={'Input Mask (JS)'}
          onChangeText={(newType) =>
            changeHandler('params.inputMaskJS', newType)
          }
          errorMessage={getErrorMessage('params.inputMaskJS')}
          placeholder={`(Select input mask)`}
          helpText={
            'ref: https://github.com/CaioQuirinoMedeiros/react-native-mask-input#formatwithmaskoptions'
          }
          value={_.get(field, 'field_data.params.inputMaskJS')}
          {...includedProps}
        />
      )
    }

    if (canHaveFormulaAppliedFields.includes(typeForSubFields)) {
      configurables.push(
        <MSFESInput
          InputComponent={DynamicTextFields}
          label={'Formula Input'}
          data={{ entity: { id: entity.data.id, name: entity.data.name } }}
          key="formula-input"
          title={'Formula Input'}
          helpText={'Enter a formula to update this field contents.'}
          changeHandler={(newFormula) => {
            changeHandlerDebounced('params.contentsFormula', newFormula)
          }}
          defaultValue={_.get(field, 'field_data.params.contentsFormula')}
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          InputComponent={SelectWithData}
          label={'Format result as'}
          data={{ entity: { id: entity.data.id, name: entity.data.name } }}
          key="formula-input"
          dataRows={[
            {
              type: 'duration',
              name: 'Duration'
            },
            {
              type: 'date',
              name: 'Date'
            }
          ]}
          title={'Format result as'}
          helpText={
            "If the output needs further formatting, select here (e.g. turning milliseconds into Hours/Minutes is 'Duration')."
          }
          onChangeText={(newType) =>
            changeHandler('params.formatResultAs', newType.type)
          }
          errorMessage={getErrorMessage('params.formatResultAs')}
          placeholder={`(Select output post-formatter)`}
          value={_.get(field, 'field_data.params.formatResultAs')}
          valueField="type"
          labelField="name"
          keyField="type"
          {...includedProps}
        />
      )
    }

    if (!isInsideQuiz) {
      configurables.push(
        <MSFESInput
          key="nfirs_pad_length"
          label={'NFIRS Max Length'}
          title={'NFIRS Max Length'}
          helpText={'Enter the length of this field in the NFIRS spec.'}
          keyboardType="numeric"
          onChangeText={(min) => {
            changeHandler('params.nfirs_pad_length', min)
          }}
          value={_.get(field, 'field_data.params.nfirs_pad_length')}
          {...includedProps}
        />
      )
    }

    if (!isInsideQuiz) {
      configurables.push(
        <MSFESInput
          key="overwrite_field_value_with"
          label={'Overwrite Field Value With Template'}
          title={'Overwrite Field Value With Template'}
          helpText={
            'Enter a template to overwrite this field value with (Field names only, such as tenant.name).'
          }
          onChangeText={(min) => {
            changeHandler('params.overwrite_field_value_with_template', min)
          }}
          value={_.get(
            field,
            'field_data.params.overwrite_field_value_with_template'
          )}
          {...includedProps}
        />
      )
    }
    if (!isInsideQuiz) {
      configurables.push(
        <MSFESInput
          key="fill_with_value_when_empty"
          label={'Fill field with value when empty'}
          title={'Fill field with value when empty'}
          helpText={
            'Enter a value to overwrite this field value with when empty (Real values only, such as "Mr Sample").'
          }
          onChangeText={(min) => {
            changeHandler('params.fill_with_value_when_empty', min)
          }}
          value={_.get(field, 'field_data.params.fill_with_value_when_empty')}
          {...includedProps}
        />
      )
    }

    if (typeForSubFields == 'approval-required') {
      configurables.push(
        <MSFESInput
          horizontal
          InputComponent={MultipleRoleSelector}
          label="Approver Roles"
          defaultValue={field.field_data?.params?.approverRoles}
          onChangeText={(newValue) => {
            changeHandler('params.approverRoles', newValue)
          }}
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          label={'Notify Approver on create'}
          value={_.get(field, 'field_data.params.notifyApprover')}
          InputComponent={WrappedSwitch}
          onValueChange={() => {
            const newNotify = !_.get(
              field,
              'field_data.params.notifyApprover',
              false
            )
            changeHandler('params.notifyApprover', newNotify)
          }}
          {...includedProps}
        />
      )
    }

    if (typeForSubFields == 'quiz-question-group') {
      configurables.push(
        <MSFESInput
          horizontal
          InputComponent={RichTextContainer}
          label="Quiz Introduction"
          defaultValue={field.field_data?.params?.quizIntroduction}
          onChangeText={(newValue) => {
            changeHandler('params.quizIntroduction', newValue)
          }}
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          key="quiz_weight_in_course"
          label={'Quiz Weight In Course'}
          value={_.get(field, 'field_data.params.quiz_weight_in_course', 1)}
          keyboardType="numeric"
          onChangeText={(value) => {
            changeHandler('params.quiz_weight_in_course', value)
          }}
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          key="quiz_pass_mark_percent"
          label={'Pass Mark (%)'}
          value={_.get(field, 'field_data.params.quiz_pass_mark_percent')}
          keyboardType="numeric"
          onChangeText={(value) => {
            changeHandler('params.quiz_pass_mark_percent', value)
          }}
          {...includedProps}
        />
      )

      configurables.push(
        <MSFESInput
          key="randomize_questions"
          label={'Randomize Questions'}
          value={_.get(field, 'field_data.params.randomize_questions')}
          InputComponent={WrappedSwitch}
          onValueChange={() => {
            const newRandomize = !_.get(
              field,
              'field_data.params.randomize_questions',
              false
            )
            changeHandler('params.randomize_questions', newRandomize)
          }}
          {...includedProps}
        />
      )
    }

    if (
      typeForSubFields == 'quiz-question-group' &&
      _.get(field, 'field_data.params.randomize_questions', false)
    ) {
      configurables.push(
        <MSFESInput
          key="qs_from_pool"
          label={'Number of questions from pool (0 = all)'}
          keyboardType="numeric"
          onChangeText={(min) => {
            changeHandler('params.quiz_number_of_questions', min)
          }}
          value={_.get(field, 'field_data.params.quiz_number_of_questions', 0)}
          {...includedProps}
        />
      )
    }

    if (typeForSubFields == 'quiz-question-group') {
      configurables.push(
        <>
          <MSFESHeading>Attempts (Not wired up)</MSFESHeading>

          <MSFESInput
            horizontal
            InputComponent={RadioWithData}
            label={'Attempts'}
            dataRows={[
              {
                type: 'one-attempt',
                name: 'One (1) Attempt'
              },
              {
                type: 'multiple-attempts',
                name: 'Multiple Attempts (must be two (2) or higher)'
              },
              {
                type: 'unlimited-attempts',
                name: 'Unlimited Attempts'
              }
            ]}
            onChangeText={(newOption) =>
              changeHandler('params.quizAttemptsOption', newOption.type)
            }
            placeholder={`(Select Attempts)`}
            value={field?.field_data?.params?.quizAttemptsOption}
            valueField="type"
            labelField="name"
            keyField="type"
            {...includedProps}
          />

          {field?.field_data?.params?.quizAttemptsOption ===
            'multiple-attempts' && (
            <View>
              <MSFESInput
                horizontal
                label="Maximum Attempts"
                name={'maximum_attempts'}
                errorMessage={null}
                value={field?.field_data?.params?.quizMaximumAttempts}
                onChangeText={(newValue) => {
                  changeHandler('params.quizMaximumAttempts', newValue)
                }}
                {...includedProps}
              />
            </View>
          )}

          <MSFESHeading>Time Limit (minutes) (Not wired up)</MSFESHeading>
          <MSFESInput
            horizontal
            label="Time Limit"
            name={'time_limit'}
            errorMessage={null}
            defaultValue={field?.field_data?.params?.quizTimeLimit}
            onChangeText={(newValue) => {
              changeHandler('params.quizTimeLimit', newValue)
            }}
            {...includedProps}
          />
        </>
      )
    }

    if (!isInsideQuiz && isInternalRole) {
      configurables.push(
        <MSFESInput
          key="neris_property"
          label={'NERIS Property'}
          title={'NERIS Property'}
          helpText={'Enter the property name in the NERIS spec.'}
          onChangeText={(value) => {
            changeHandler('params.neris_property', value)
          }}
          {...includedProps}
          value={_.get(field, 'field_data.params.neris_property')}
        />
      )
    }
    if (!isInsideQuiz && isInternalRole) {
      configurables.push(
        <MSFESInput
          key="nemsis_property"
          label={'NEMSIS Property'}
          title={'NEMSIS Property'}
          helpText={'Enter the property name in the NEMSIS spec.'}
          onChangeText={(value) => {
            changeHandler('params.nemsis_property', value)
          }}
          {...includedProps}
          value={_.get(field, 'field_data.params.nemsis_property')}
        />
      )
    }

    if (!isInsideQuiz) {
      if (field?.field_data?.params?.alarmCondition) {
        configurables.push(
          <MSFESInput
            InputComponent={QueryEditorField}
            key="alarm-condition"
            label={'(Deprecated) Alarm Condition'}
            title={'(Deprecated) Alarm Condition'}
            helpText={
              'When this formula evaluates to true, the data will be presented in an alarm state.'
            }
            changeHandler={(value) => {
              changeHandler('params.alarmCondition', value)
            }}
            data={{ entity: { entity_type_target: { id: entity.data.id } } }}
            defaultValue={_.get(field, 'field_data.params.alarmCondition')}
            style={hasBottomBorder}
            {...includedProps}
          />
        )
      }

      if (field?.field_data?.params?.alarmConditionColor) {
        configurables.push(
          <MSFESInput
            InputComponent={ColorPickerField}
            key="alarm-condition-color"
            label={'(Deprecated) Alarm Condition Color'}
            title={'(Deprecated) Alarm Condition Color'}
            changeHandler={(color) => {
              changeHandler('params.alarmConditionColor', color)
            }}
            data={{ entity: { entity_type_target: { id: entity.data.id } } }}
            defaultValue={_.get(field, 'field_data.params.alarmConditionColor')}
            style={hasBottomBorder}
            {...includedProps}
          />
        )
      }
    }

    if (typeForSubFields == 'query-logic') {
      configurables.push(
        <MSFESInput
          key="query-entity-type"
          InputComponent={SelectWithData}
          label={'Force Query Entity Type Target'}
          dataRows={[...entityTypes, CURRENT_USER_ENTITY_TYPE]}
          onChangeText={(newType) => {
            changeHandler('params.entityTypeTarget', newType.name)
          }}
          errorMessage={getErrorMessage('params.entityTypeTarget')}
          value={_.get(field, 'field_data.params.entityTypeTarget', null)}
          placeholder={`(Select source list)`}
          valueField="name"
          labelField="label"
          keyField="name"
          {...includedProps}
        />
      )
    }

    return configurables
  }, [isMatrix, supportedFieldTypes, entityTypes, title, field, entity])

  const pageRows = []
  for (let i = 0; i < pages; i++) {
    pageRows.push({
      id: i + 1,
      label: entity?.data?.object_data?.page_labels?.[i] ?? 'Page ' + (i + 1),
      value: i + 1
    })
  }

  const availableFieldTypes = supportedFieldTypes.filter((f) => {
    const canAssign = _.get(f, 'canAssignToEntityType', true)
    return canAssign === true || (canAssign === 'global-only' && isGlobalAdmin)
  })

  return (
    <>
      <View>
        <ListItem
          onPress={toggleExpanded}
          onLongPress={onLongPress}
          topDivider={true}
        >
          <ListItem.Content>
            <ListItem.Title>
              {title} <Text style={{ color: '#aaa' }}>({propertyEntry})</Text>
            </ListItem.Title>
            <ListItem.Subtitle>{nameForType}</ListItem.Subtitle>
            {isInsideFieldName && (
              <ListItem.Subtitle>Inside {isInsideFieldName}</ListItem.Subtitle>
            )}
            {hasErrors && (
              <ListItem.Subtitle style={{ color: 'red' }}>
                Please correct errors before resubmitting.
              </ListItem.Subtitle>
            )}
          </ListItem.Content>

          {!isExpanded && <ListItem.Chevron />}
        </ListItem>
        {isExpanded && (
          <Overlay
            isVisible={true}
            onBackdropPress={toggleExpanded}
            overlayStyle={{
              maxHeight: maxOverlayHeight,
              minWidth: 360,
              flexDirection: 'column'
            }}
          >
            <OverlayHeader>Field Editor</OverlayHeader>
            <ScrollView
              style={{ maxHeight: maxOverlayHeight, flex: 1, minWidth: 360 }}
            >
              <View style={{ minWidth: '100%' }}>
                <MSFESInput
                  horizontal
                  label={'Label'}
                  style={{ ...nonInstanceFieldStyle }}
                  errorMessage={getErrorMessage('title')}
                  // onChangeText={(newTitle) => changeTitleHandler(newTitle)}
                  onBlur={(e) => {
                    const value =
                      Platform.OS === 'web'
                        ? e.target.value
                        : e.nativeEvent.text
                    changeTitleHandler(value)
                  }}
                  placeholder={`Enter field title`}
                  defaultValue={titleEntry}
                  {...includedProps}
                />

                <MSFESInput
                  horizontal
                  InputComponent={SelectWithData}
                  style={{ ...nonInstanceFieldStyle }}
                  label={'Type'}
                  dataRows={availableFieldTypes}
                  onChangeText={(newType) =>
                    changeHandler('type', newType.type)
                  }
                  errorMessage={getErrorMessage('type')}
                  placeholder={`(Select ${title} type)`}
                  value={type}
                  valueField="type"
                  labelField="name"
                  keyField="type"
                  {...includedProps}
                />
                {!field.tenant_id && (
                  <List.AccordionGroup>
                    <MSFESAccordion title="Instance Config" id="instance">
                      <MSFESInput
                        horizontal
                        label={'Label'}
                        onBlur={(e) => {
                          const value =
                            Platform.OS === 'web'
                              ? e.target.value
                              : e.nativeEvent.text
                          changeHandlerDebounced('instance_data.title', value)
                        }}
                        onChangeText={(newProperty) =>
                          changeHandlerDebounced(
                            'instance_data.title',
                            newProperty
                          )
                        }
                        style={{ ...instanceFieldStyle }}
                        errorMessage={getErrorMessage('instance_data.title')}
                        defaultValue={
                          field.field_data?.instance_data?.title ?? null
                        }
                        placeholder={field?.field_data?.title}
                        {...includedPropsTenant}
                      />
                      <MSFESInput
                        horizontal
                        label={'Sort Index'}
                        onBlur={(e) => {
                          const value =
                            Platform.OS === 'web'
                              ? e.target.value
                              : e.nativeEvent.text
                          changeHandlerDebounced(
                            'instance_data.sort_order',
                            value
                          )
                        }}
                        style={{ ...instanceFieldStyle }}
                        onChangeText={(newProperty) =>
                          changeHandlerDebounced(
                            'instance_data.sort_order',
                            newProperty
                          )
                        }
                        errorMessage={getErrorMessage(
                          'instance_data.sort_order'
                        )}
                        defaultValue={
                          field.field_data?.instance_data?.sort_order ?? null
                        }
                        placeholder={field?.field_data?.sort_order}
                        {...includedPropsTenant}
                      />

                      <MSFESInput
                        horizontal
                        supportsInputShading={false}
                        InputComponent={WrappedSwitch}
                        label={'Visibility'}
                        title={'Visibility'}
                        onValueChange={(newValue) => {
                          changeHandler('instance_data.visibility', newValue)
                        }}
                        style={{ ...instanceFieldStyle }}
                        value={
                          field.field_data?.instance_data?.visibility !==
                          undefined
                            ? field.field_data?.instance_data?.visibility
                            : _.get(field, 'field_data.params.visibility', true)
                        }
                        {...includedPropsTenant}
                      />

                      <MSFESInput
                        horizontal
                        supportsInputShading={false}
                        InputComponent={WrappedSwitch}
                        label={'Table View'}
                        title={'Table View'}
                        onValueChange={(newValue) => {
                          changeHandler('instance_data.tableView', newValue)
                        }}
                        style={{ ...instanceFieldStyle }}
                        value={
                          field.field_data?.instance_data?.tableView !==
                          undefined
                            ? field.field_data?.instance_data?.tableView
                            : _.get(field, 'field_data.params.tableView', true)
                        }
                        {...includedPropsTenant}
                      />
                      <MSFESInput
                        horizontal
                        supportsInputShading={false}
                        InputComponent={WrappedSwitch}
                        label={'Table Sortable'}
                        title={'Table Sortable'}
                        onValueChange={(newValue) => {
                          changeHandler('instance_data.tableSortable', newValue)
                        }}
                        style={{ ...instanceFieldStyle }}
                        value={
                          field.field_data?.instance_data?.tableSortable !==
                          undefined
                            ? field.field_data?.instance_data?.tableSortable
                            : _.get(
                                field,
                                'field_data.params.tableSortable',
                                true
                              )
                        }
                        {...includedPropsTenant}
                      />

                      <MSFESInput
                        horizontal
                        InputComponent={SelectWithData}
                        dataRows={pageRows}
                        label={'Page'}
                        onChangeText={(newProperty) => {
                          changeHandlerDebounced(
                            'instance_data.page',
                            newProperty.id
                          )
                          setActivePage(newProperty.id)
                        }}
                        style={{ ...instanceFieldStyle }}
                        labelField="label"
                        placeholder={field.field_data?.page}
                        value={
                          field.field_data?.instance_data?.page !== undefined
                            ? field.field_data?.instance_data?.page
                            : _.get(field, 'field_data.page', true)
                        }
                        defaultValue={
                          field.field_data?.instance_data?.page !== undefined
                            ? field.field_data?.instance_data?.page
                            : _.get(field, 'field_data.page', true)
                        }
                        {...includedPropsTenant}
                      />
                    </MSFESAccordion>
                  </List.AccordionGroup>
                )}
                <List.AccordionGroup>
                  <MSFESAccordion title="Properties" id="properties">
                    <MSFESInput
                      horizontal
                      label={'Property'}
                      onChangeText={(newProperty) =>
                        changeHandlerDebounced('property', newProperty)
                      }
                      errorMessage={getErrorMessage('property')}
                      placeholder={`Enter field property`}
                      defaultValue={propertyEntry}
                      {...includedProps}
                    />
                    <MSFESInput
                      horizontal
                      label={'Help Text'}
                      onChangeText={(newProperty) =>
                        changeHandlerDebounced('helpText', newProperty)
                      }
                      errorMessage={getErrorMessage('helpText')}
                      placeholder={`Enter help text`}
                      defaultValue={_.get(field, 'field_data.helpText', null)}
                      {...includedProps}
                    />
                    <MSFESInput
                      label={'Sort Index'}
                      horizontal
                      onChangeText={(newProperty) =>
                        changeHandlerDebounced('sort_order', newProperty)
                      }
                      errorMessage={getErrorMessage('field_data.sort_order')}
                      placeholder={`Enter sort index`}
                      defaultValue={_.get(field, 'field_data.sort_order', null)}
                      {...includedProps}
                    />
                    <MSFESInput
                      label={'Table Sort Index'}
                      horizontal
                      onChangeText={(newProperty) =>
                        changeHandlerDebounced('table_sort_order', newProperty)
                      }
                      errorMessage={getErrorMessage(
                        'field_data.table_sort_order'
                      )}
                      placeholder={`Enter table sort index`}
                      defaultValue={_.get(
                        field,
                        'field_data.table_sort_order',
                        null
                      )}
                      {...includedProps}
                    />

                    <MSFESInput
                      key="dataSharingType"
                      horizontal
                      InputComponent={SelectListMultiple}
                      label={'Data Sharing Type'}
                      onChangeText={(newValue) =>
                        changeHandlerDebounced('dataSharingType', newValue)
                      }
                      defaultValue={_.castArray(
                        field.field_data?.dataSharingType
                      )}
                      errorMessage={getErrorMessage('dataSharingType')}
                      placeholder={`(Select Data Sharing`}
                      data={{
                        field: {
                          field_data: {
                            params: {
                              ...{ sourceName: 'entity_type_data_sharing_type' }
                            }
                          }
                        }
                      }}
                      {...includedProps}
                    />

                    <MSFESInput
                      label={'Icon'}
                      helpText={
                        'Not all containers will use the field icon. Tile Container fields use the field icon.'
                      }
                      horizontal
                      InputComponent={SelectIcon}
                      onChangeText={(newProperty) =>
                        changeHandlerDebounced('icon', newProperty)
                      }
                      errorMessage={getErrorMessage('field_data.icon')}
                      placeholder={`Select icon`}
                      defaultValue={_.get(field, 'field_data.icon', null)}
                      {...includedProps}
                    />
                    <MSFESInput
                      horizontal
                      InputComponent={SelectWithData}
                      dataRows={pageRows}
                      label={'Page'}
                      onChangeText={(newProperty) => {
                        changeHandlerDebounced('page', newProperty.id)
                        setActivePage(newProperty.id)
                      }}
                      labelField="label"
                      errorMessage={getErrorMessage('field_data.page')}
                      placeholder={`Enter page`}
                      value={_.get(field, 'field_data.page', 1)}
                      defaultValue={_.get(field, 'field_data.page', 1)}
                      {...includedProps}
                    />

                    {canRequire && (
                      <MSFESInput
                        horizontal
                        supportsInputShading={false}
                        InputComponent={WrappedSwitch}
                        label={'Required'}
                        title={'Required'}
                        onValueChange={() => {
                          const newRequired = !required
                          changeHandler('validation.required', newRequired)
                        }}
                        value={required}
                        {...includedProps}
                      />
                    )}

                    <MSFESInput
                      horizontal
                      supportsInputShading={false}
                      InputComponent={WrappedSwitch}
                      label={'Visibility'}
                      title={'Visibility'}
                      onValueChange={(newValue) => {
                        changeHandler('params.visibility', newValue)
                      }}
                      value={_.get(field, 'field_data.params.visibility', true)}
                      {...includedProps}
                    />
                    <MSFESInput
                      horizontal
                      supportsInputShading={false}
                      InputComponent={WrappedSwitch}
                      label={'Read Only'}
                      title={'Read Only'}
                      onValueChange={(newValue) => {
                        changeHandler('params.readonly', newValue)
                      }}
                      value={_.get(field, 'field_data.params.readonly', false)}
                      {...includedProps}
                    />
                    <MSFESInput
                      horizontal
                      supportsInputShading={false}
                      InputComponent={WrappedSwitch}
                      label={'Hide when Public Form'}
                      title={'Hide when Public Form'}
                      onValueChange={(hide_when_public_form) => {
                        changeHandler(
                          'params.hide_when_public_form',
                          hide_when_public_form
                        )
                      }}
                      value={
                        field?.field_data?.params?.hide_when_public_form ??
                        false
                      }
                      {...includedProps}
                    />
                    <MSFESInput
                      horizontal
                      supportsInputShading={false}
                      InputComponent={WrappedSwitch}
                      label={'Updatable Only when Null'}
                      title={'Updatable Only when Null'}
                      onValueChange={(updatable_only_when_null) => {
                        changeHandler(
                          'params.updatable_only_when_null',
                          updatable_only_when_null
                        )
                      }}
                      value={
                        field?.field_data?.params?.updatable_only_when_null ??
                        false
                      }
                      {...includedProps}
                    />
                    {canBeCreateOnly && (
                      <MSFESInput
                        horizontal
                        supportsInputShading={false}
                        InputComponent={WrappedSwitch}
                        label={'Updatable Only On Create'}
                        title={'Updatable Only On Create'}
                        onValueChange={() => {
                          const newValue = !on_create_only
                          changeHandler('validation.on_create_only', newValue)
                        }}
                        value={on_create_only}
                        {...includedProps}
                      />
                    )}
                  </MSFESAccordion>
                  {typeObject && (
                    <MSFESAccordion
                      title={`${typeObject.name} Config`}
                      description={`(${configurables.length} options)`}
                      id="field_type_flags"
                    >
                      {configurables}
                      {!configurables.length && (
                        <CurrentlyNoLabelOnly
                          key="none"
                          label={'No config settings for ' + typeObject.name}
                        />
                      )}
                    </MSFESAccordion>
                  )}
                  <MSFESAccordion title="Flags" id="flags">
                    {flaggables.map((flaggable, index) => {
                      const currentState = _.get(
                        field,
                        `field_data.flags.${flaggable}`,
                        false
                      )
                      return (
                        <MSFESInput
                          horizontal
                          key={index}
                          InputComponent={WrappedSwitch}
                          supportsInputShading={false}
                          center
                          label={capitalCase(flaggable)}
                          onValueChange={() => {
                            const newState = !currentState
                            changeHandler(`flags.${flaggable}`, newState)
                          }}
                          value={currentState}
                          {...includedProps}
                        />
                      )
                    })}
                  </MSFESAccordion>
                  <MSFESAccordion title="Approvers" id="approvers">
                    <Text style={{ padding: spacing.m2 }}>
                      Changes made to this field will not be applied until
                      approved by an approver below.
                    </Text>
                    <MSFESInput
                      horizontal
                      InputComponent={MultipleRoleSelector}
                      label="Approver Roles"
                      defaultValue={field.field_data?.params?.approverRoles}
                      onChangeText={(newValue) => {
                        changeHandler('params.approverRoles', newValue)
                      }}
                      {...includedProps}
                    />
                  </MSFESAccordion>
                  <MSFESAccordion title="Permissions" id="permissions">
                    <MSFESLabel label="Visible only to roles" />
                    <MultipleRoleSelector
                      excludedRoles={[globalAdminRole]}
                      onValueChange={(value) => {
                        changeHandler(`permissions.visible_to`, value)
                      }}
                      value={_.get(field, 'field_data.permissions.visible_to', [
                        allKey
                      ])}
                      {...includedProps}
                    />
                    <MSFESLabel label="Modifiable only by roles" />
                    <MultipleRoleSelector
                      excludedRoles={[globalAdminRole]}
                      onValueChange={(value) => {
                        changeHandler(`permissions.modifiable_by`, value)
                      }}
                      value={_.get(
                        field,
                        'field_data.permissions.modifiable_by',
                        [allKey]
                      )}
                      {...includedProps}
                    />
                  </MSFESAccordion>
                </List.AccordionGroup>
              </View>
            </ScrollView>
            <View style={{ flexDirection: 'row' }}>
              <MSFESButton
                icon={{ type: 'font-awesome', name: 'trash' }}
                type="delete"
                onPress={onDeleteFieldPressed}
              ></MSFESButton>
              <View style={{ flex: 1 }}></View>
              <MSFESButton
                onPress={toggleExpanded}
                title="Close &amp; Continue"
              ></MSFESButton>
            </View>

            {deleteFieldPending && (
              <YesNoCancelDialog
                title="delete field?"
                body="This will delete the field permanently. Continue?"
                yesAction={yesDeleteField}
                noAction={noDeleteField}
                cancelAction={noDeleteField}
              />
            )}
          </Overlay>
        )}
      </View>
    </>
  )
}

export default withTheme(EntityTypeField)
