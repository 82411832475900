import React from 'react'
import { UsaStates } from 'usa-states'
import SelectWithData from './SelectWithData'

export const SelectState = React.forwardRef((props, ref) => {
  const states = new UsaStates().states

  return (
    <SelectWithData
      {...props}
      dataRows={states}
      value={props.defaultValue}
      labelField="name"
      keyField="abbreviation"
      valueField="abbreviation"
    />
  )
})
