import _ from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectMultipleWithData from './SelectMultipleWithData'

//actions
import entityActions, { getFilterString } from '../../store/entity/actions'
import { sanitiseFilterString } from '../../libraries/sanitiseFilterString'
import { shouldRefetch } from './RadioList'
import PublicFormContext from '../../contexts/PublicFormContext'
import { evaluateRule } from '../query/evaluateRule'
import { flattenEntity } from '../../helpers/entity'

const _SelectListMultiple = (props, ref) => {
  const dispatch = useDispatch()
  const publicFormContext = useContext(PublicFormContext)

  const [nextPages, setNextPages] = useState({})

  const sourceNames = _.castArray(props.data.field.field_data.params.sourceName)

  const filterRule =
    props?.data?.field?.field_data?.params?.optionsFilter?.json?.logic

  const filter = filterRule ? { detailed: true } : null
  const filterString = sanitiseFilterString(getFilterString(null, filter))

  const sourceListRoots = sourceNames.map((sourceName) => {
    return useSelector((state) =>
      sourceName
        ? _.get(state.entities, `[${sourceName + filterString}]`, {
            data: []
          })
        : []
    )
  })

  const fetchNextPage = (index) => {
    if (_.get(nextPages, index, false) !== -1) {
      let pageToFetch = nextPages[index]

      try {
        pageToFetch = sourceListRoots[index].meta.current_page + 1
      } catch (e) {
        // doesn't matter.
      }
      if (!pageToFetch || pageToFetch > sourceListRoots[index].meta.last_page) {
        pageToFetch = 1
      }

      dispatch(
        entityActions.listEntity(
          { name: sourceNames[index] },
          { filter, page: pageToFetch },
          undefined,
          publicFormContext.entityType,
          {
            tenantId: publicFormContext.tenantId,
            tenantHmac: publicFormContext.tenantHmac
          }
        )
      ).then((response) => {
        if (!nextPages[index] || nextPages[index] <= response.meta.last_page) {
          if (response.meta.current_page < response.meta.last_page) {
            setNextPages((nextPages) => ({
              ...nextPages,
              ...{ [index]: response.meta.current_page + 1 }
            }))
          } else {
            setNextPages((nextPages) => ({ ...nextPages, ...{ [index]: -1 } }))
          }
        } else {
          setNextPages((nextPages) => ({ ...nextPages, ...{ [index]: -1 } }))
        }
      })
    }
  }

  useEffect(() => {
    sourceListRoots.map((sourceListRoot, index) => {
      if (shouldRefetch(sourceListRoot)) {
        fetchNextPage(index)
      } else {
        setNextPages((nextPages) => ({ ...nextPages, ...{ [index]: 1 } }))
      }
    })
  }, [])

  const sourceData = useMemo(() => {
    const data = _.flatten(
      sourceListRoots.map((sourceListRoot) => {
        return _.get(sourceListRoot, 'data', [])
      })
    )

    const filteredData = filterRule
      ? data.filter((d) =>
          evaluateRule(filterRule, {
            ...flattenEntity(d, false),
            field: flattenEntity(props.data.entity, false)
          })
        )
      : data

    return filteredData
  }, [sourceListRoots])

  const groupByFunction =
    sourceListRoots.length > 1
      ? (f) => {
          return f.entity_type?.label
        }
      : undefined

  const sortByFunction = (el) => ('' + el.display_name).toLowerCase()

  return (
    <SelectMultipleWithData
      {...props}
      dataRows={sourceData}
      groupByFunction={groupByFunction}
      sortByFunction={sortByFunction}
      labelField={'display_name'}
      onEndReached={() => {
        // console.log("End reached.", sourceListRoots);
        sourceListRoots.map((sourceListRoot, index) => {
          fetchNextPage(index)
        })
      }}
      value={props.defaultValue}
    />
  )
}
export const SelectListMultiple = React.forwardRef(_SelectListMultiple)
