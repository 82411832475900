// libraries
import React from 'react'

import { EntityTypeFieldValueMapper } from './EntityTypeFieldValueMapper'

export const MapFieldsToValues = (props) => {
  const { entityType, value, changeHandler } = props

  return (
    <EntityTypeFieldValueMapper
      sourceEntityType={entityType}
      mappedFields={value}
      onFieldmapChanged={(fieldmap) => changeHandler(fieldmap)}
    />
  )
}

export default MapFieldsToValues
