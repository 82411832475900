import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Input } from 'react-native-elements'
import {
  View,
  ActivityIndicator,
  Platform,
  StyleSheet,
  Dimensions
} from 'react-native'
import colors from '../styles/colors'

import _ from 'lodash'
import MSFESLabel from './MSFESLabel'
import MSFESSubLabel from './MSFESSubLabel'
import spacing from '../styles/spacing'
import shared from '../styles/shared'
import { InputTextStyle } from './ListItemAsFieldProps'
import BarcodeScannerWrapper from './BarcodeScannerWrapper'
import CrossFieldRenderAsNullContext from '../contexts/CrossFieldRenderAsNullContext'
import { PendingChange } from './PendingChange'
import ClearButton from './ClearButton'


export const getValueFromEvent = (e) => {
  return Platform.OS === 'web' ? e.target.value : e.nativeEvent.text
}
export const getLabelDirectionStyle = (horizontal, width = 480) => {
  const fieldWidth = width >= 768 ? 240 : width > 480 ? 180 : 120
  return horizontal
    ? {
        marginBottom: 0,
        paddingBottom: 0,
        marginTop: 12,
        paddingRight: 8,
        minWidth: fieldWidth,
        maxWidth: fieldWidth
      }
    : { marginVertical: spacing.m1 }
}
export default function MSFESInput(props) {
  const {
    helpText = null,
    adminLabel = null,
    isDisabledFromPermissions = false,
    hasVisibilityRules = false,
    renderStaticHeader = false,
    canScanBarcode = false,
    inputInternalStyle = null,
    style,
    userCanApprove = false,
    showLabel = true,
    sublabel = null,
    value,
    dataAwaitingApproval = null,
    pendingApprovals = [],
    onApproveToggled,
    loading = false,
    supportsInputShading = true,
    showClear = false,
    clearValue = ''
  } = props

  const { crossFieldRenderAsNull } = useContext(CrossFieldRenderAsNullContext)
  const crossFieldThisFieldShouldNotRender =
    _.get(
      crossFieldRenderAsNull,
      `suppressObject[${props.data?.field?.field_data?.property}]`,
      true
    ) === false

  let titleColor = 'white'

  if (props.dark) {
    titleColor = colors.inputLabelDark
  } else {
    titleColor = colors.inputLabel
  }

  let inputColor

  if (props.dark) {
    inputColor = colors.inputTextDark
  } else {
    inputColor = colors.inputText
  }

  let backgroundColor = supportsInputShading ? colors.formInputBackground : null

  let placeholderTextColor = colors.inputBottomBorder

  if (props.signin) {
    inputColor = colors.blueDarkest
    backgroundColor = colors.orangeLighter
    placeholderTextColor = colors.redDark
  }

  const { alarmColor } = useMemo(() => {
    const alarmIndex = props.data?.entity?._fieldsInAlarmState?.indexOf(
      props.data?.field?.field_data?.property
    )
    const alarmColor = (props.data?.entity?._alarmStateColors ?? [])[alarmIndex]

    if (alarmColor) {
      return {
        alarmColor: alarmColor
      }
    } else {
      return { alarmColor: null }
    }
  }, [props.data?.entity, props.data?.field])

  const inputContainerStyle = {
    borderColor: props.errorMessage
      ? colors.validationErrorBackgroundDark
      : alarmColor
      ? alarmColor
      : 'transparent',
    borderBottomWidth: 2,
    borderRadius: spacing.borderRadius,
    ...props.inputContainerStyle
  }

  // set up container style
  const containerStyle = [
    props.containerStyle,
    {
      flex: 1,
      paddingHorizontal: 0,
      width: null,
      borderRadius: shared.borderRadius
      // borderWidth: 2, borderColor:"yellow"
    },
    props.horizontal ? { flex: 1 } : null
  ] //, flex: 1

  const errorStyle = { margin: 2, color: colors.validationErrorBackgroundDark }

  // set up title text
  const { label, editable, disabled } = props

  const loadingProps = {
    color: titleColor
  }

  const inputStyle = [
    InputTextStyle,
    {
      color: inputColor,
      paddingLeft: spacing.m2,
      backgroundColor,
      borderRadius: spacing.borderRadius,
      marginTop: 0,
      opacity: props.disabled ? 1 : 1,
      borderWidth:
        props.horizontal && editable ? StyleSheet.hairlineWidth : undefined,
      borderColor: 'white'
    },
    props.inputStyle
  ]

  if (props.rightIcon && props.rightIcon.name) {
    props.rightIcon.color = colors.iconDark
  }

  const propsFiltered = useMemo(() => {
    return _.omitBy(props, (v, k) => {
      return ['label', 'style', 'disabled'].indexOf(k) !== -1
    })
  }, [props])

  const required = _.get(
    props,
    'data.field.field_data.validation.required',
    false
  )

  const directionStyle = props.horizontal
    ? { flexDirection: 'row' }
    : { flexDirection: 'column' } //, paddingRight: spacing.m2
  const { width } = Dimensions.get('window')
  const labelDirectionStyle = useMemo(
    () => getLabelDirectionStyle(props.horizontal, width),
    [props.horizontal, width]
  )
  const inputDirectionStyle = props.horizontal ? { flex: 1, width: null } : null

  const [renderKey, setRenderKey] = useState(0)
  const [prevState, setPrevState] = useState(null)

  useEffect(() => {
    if (
      JSON.stringify(props.defaultValue) != JSON.stringify(prevState) &&
      props.defaultValue !== undefined &&
      props.defaultValueCanBeUpdated
    ) {
      setPrevState(props.defaultValue)
      setRenderKey(renderKey + 1)
    }
  }, [props.defaultValue])

  // renderStaticHeader -
  // there can be field groups (e.g. 4-5 YES/NO's in a row)
  // that need to render a column header
  // only in the first component.
  // when so, renderStaticHeader will be true and will add itself some padding
  // and propagate to the child.
  // driven by supportedFieldTypes.canHaveStaticHeader: true

  const renderStaticHeaderModifier = renderStaticHeader
    ? { marginTop: 30 }
    : null

  const showSublabel = !!(sublabel && value)

  const containerViewStyle = [
    style,
    directionStyle,
    renderStaticHeaderModifier,
    shared.debugOff
  ]

  const showBarcodeOption = canScanBarcode && !disabled && Platform.OS !== 'web'
  const showClearOption =
    showClear &&
    !disabled &&
    props.defaultValue !== undefined &&
    props.defaultValue !== '' &&
    props.defaultValue !== clearValue

  const willShowBarcodeOption =
    showBarcodeOption && !crossFieldThisFieldShouldNotRender

  const rightIcon = crossFieldThisFieldShouldNotRender
    ? undefined
    : props.rightIcon

  const offsetClearIcon =
    willShowBarcodeOption ||
    rightIcon ||
    ['select-list'].includes(props.data?.field?.field_data?.type)

  return (
    <View style={containerViewStyle}>
      <View style={props.horizontal ? { maxWidth: '50%' } : undefined}>
        {showLabel && !crossFieldThisFieldShouldNotRender && (
          <MSFESLabel
            style={labelDirectionStyle}
            labelColor={
              props.errorMessage
                ? colors.validationErrorBackgroundDark
                : alarmColor
                ? alarmColor
                : undefined
            }
            label={label}
            helpText={helpText}
            dataAwaitingApproval={dataAwaitingApproval}
            isError={!!props.errorMessage}
            isDisabledFromPermissions={isDisabledFromPermissions}
            hasVisibilityRules={hasVisibilityRules}
            required={editable && required}
          />
        )}
      </View>
      {userCanApprove && (
        <PendingChange
          onApproveChecked={onApproveToggled}
          checked={pendingApprovals.includes(
            props.data?.field?.field_data?.property
          )}
          value={dataAwaitingApproval}
        />
      )}
      {!loading && (
        <View
          style={[
            { flexDirection: 'row', flex: 1, position: 'relative' },
            shared.debugOff
          ]}
        >
          <Input
            key={renderKey}
            disabled={disabled}
            {...propsFiltered}
            rightIcon={rightIcon}
            errorStyle={errorStyle}
            placeholderTextColor={placeholderTextColor}
            style={[{ minWidth: 50 }, inputInternalStyle]}
            inputStyle={inputStyle}
            inputContainerStyle={[inputContainerStyle, inputDirectionStyle]}
            containerStyle={[containerStyle]}
            disabledInputStyle={{ opacity: 1 }}
            loadingProps={loadingProps}
          />

          {showClearOption && (
            <ClearButton
              onPress={() => {
                let value = clearValue
                if (props.onBlur) {
                  // construct an event
                  props.onBlur({
                    target: { value },
                    nativeEvent: { text: value }
                  })
                } else {
                  const isUserInitiated = true
                  props.onChangeText(value, isUserInitiated)
                }
              }}
              style={{ right: offsetClearIcon ? 36 : 0 }}
            ></ClearButton>
          )}
          {willShowBarcodeOption && (
            <BarcodeScannerWrapper
              onBarcodeScanned={(value) => {
                if (props.onBlur) {
                  // construct an event
                  props.onBlur({
                    target: { value },
                    nativeEvent: { text: value }
                  })
                } else {
                  props.onChangeText(value)
                }
              }}
            />
          )}
        </View>
      )}
      {loading && <ActivityIndicator />}
      {/* {helpText && <MSFESSubLabel label={helpText} />} */}
      {showSublabel && <MSFESSubLabel label={sublabel} />}
      {adminLabel && (
        <MSFESSubLabel
          label={adminLabel}
          style={{ paddingTop: 12, paddingBottom: 24 }}
        />
      )}
    </View>
  )
}
