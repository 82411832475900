// libraries
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import _ from 'lodash'

//actions
import entityTypeActions, {
  listLiteEntityTypes
} from '../store/entity-type/actions'

// components
import { ListItemLinkButton } from '../components/ListItemLinkButton'

import WithHeader from '../components/WithHeader'
import DraggableFlatList from 'react-native-draggable-flatlist'
import { Platform, RefreshControl } from 'react-native'
import ManageEntityTypesActions from '../components/ManageEntityTypesActions'
import { useDropdownHelper } from '../useDropdownHelper'
import { DateTime } from 'luxon'
export const reflowSort = (data, property = 'sort_order') => {
  data.map((d, i) => {
    _.set(d, property, 1000 + i * 1000)
    _.set(d, 'modified_at', DateTime.now().toISO())
  })

  return data
}

export const getResortedData = (data, from, to, property = 'sort_order') => {
  if (to < data.length - 1) {
    _.set(data[to], property, _.get(data[to + 1], property) - 1)
    _.set(data[to], 'modified_at', DateTime.now().toISO())

    const isTheSameAsAbove =
      to > 0 && _.get(data[to], property) == _.get(data[to - 1], property)
    const hasDroppedNegative = _.get(data[to], property) <= 1

    if (isTheSameAsAbove || hasDroppedNegative) {
      data = reflowSort(data, property)
    }
  } else {
    _.set(data[to], property, _.get(data[to - 1], property) + 1)
    _.set(data[to], 'modified_at', DateTime.now().toISO())
  }

  return data
}

export const sortOptions = [
  { name: 'a_to_z', icon: 'sort-alpha-down' },
  { name: 'default', icon: 'sort-amount-up' },
  { name: 'z_to_a', icon: 'sort-alpha-down-alt' }
]

export const useGetEntityTypeIcon = () => {
  const current_user = useSelector((state) => {
    return state.users?.user
  })

  return useCallback(
    (item) => {
      if (item?.object_data?.icon) {
        return { name: item.object_data.icon, type: 'font-awesome' }
      } else if (current_user?.tenant_record?.SYSTEM_display_picture) {
        return {
          source: current_user?.tenant_record?.SYSTEM_display_picture,
          type: 'image'
        }
      }
    },
    [current_user]
  )
}

export default function SettingsManageEntityTypesScreen({ navigation }) {
  const dispatch = useDispatch()

  const _liteEntityTypes = useSelector(
    (state) => state.entityTypes?.dataLite?.data ?? []
  )

  // if we're on a subroute, we need to work that out.

  // TODO: Reload stubs here.
  useEffect(() => {
    dispatch(listLiteEntityTypes())
  }, [])

  const [activeSort, setActiveSort] = useState(0)
  const [liteEntityTypes, setLiteEntityTypes] = useState([])
  const [liteEntityTypesFiltered, setLiteEntityTypesFiltered] = useState([])
  useEffect(() => {
    setLiteEntityTypes(_liteEntityTypes)
  }, [_liteEntityTypes])

  const activeSortObject = sortOptions[activeSort]

  const updateSorts = (data, from, to) => {
    data = getResortedData(data, from, to)
    setLiteEntityTypes(data)
    dispatch(entityTypeActions.updateEntityTypeBatch(data))
  }

  const [searchText, setSearchText] = useState(null)
  const setSearchTextDebounced = useCallback(_.debounce(setSearchText, 200), [])

  const onSearchTextChanged = (_searchText) => {
    setSearchTextDebounced(_searchText.toLowerCase())
  }

  useEffect(() => {
    setLiteEntityTypesFiltered(
      searchText
        ? liteEntityTypes.filter(
            (e) => e.label.toLowerCase().indexOf(searchText) !== -1
          )
        : liteEntityTypes
    )
  }, [searchText, liteEntityTypes])

  const sortedLiteEntityTypes = useMemo(() => {
    switch (activeSortObject.name) {
      case 'a_to_z':
        return _.sortBy(liteEntityTypesFiltered, (e) => e.label)
      case 'default':
        return _.orderBy(liteEntityTypesFiltered, ['sort_order'], ['asc'])
      case 'z_to_a':
        return _.orderBy(liteEntityTypesFiltered, ['label'], ['desc'])
    }
  }, [liteEntityTypesFiltered, activeSortObject])

  const [refreshing, setRefreshing] = React.useState(false)
  const onRefresh = React.useCallback(() => {
    setRefreshing(true)

    // TODO: Reload stubs here
    dispatch(listLiteEntityTypes()).then(() => {
      setRefreshing(false)
    })
  }, [])

  const [
    dropdownVisibilityState,
    ,
    toggleDropdownState,
    buttonRef,
    dropdownOverlayStyle
  ] = useDropdownHelper(250)

  const rightButtonProps = {
    rightButtonAction:
      Platform.OS === 'web' ? () => toggleDropdownState(true) : null,
    rightButtonIcon: 'bars'
  }

  const onSortChanged = (newIndex) => {
    setActiveSort(newIndex)
  }

  const getEntityTypeIcon = useGetEntityTypeIcon()

  return (
    <WithHeader
      title={'Manage Entity Types'}
      dropdownVisibilityState={dropdownVisibilityState}
      rightButtonRef={buttonRef}
      onSearchTextChanged={onSearchTextChanged}
      onSortChanged={onSortChanged}
      sortOptions={sortOptions}
      activeSort={activeSort}
      navigation={navigation}
      leftButtonType={'back'}
      {...rightButtonProps}
    >
      <ManageEntityTypesActions
        dropdownOverlayStyle={dropdownOverlayStyle}
        isVisible={dropdownVisibilityState}
        onBackdropPress={() => toggleDropdownState(false)}
      />
      <ListItemLinkButton
        title={'Add Entity Type'}
        key={'add'}
        type="add"
        to={
          `/settings/admin-tools/manage-entity-types/add` +
          '?from=' +
          encodeURIComponent('/settings/admin-tools/manage-entity-types/index')
        }
      />

      <DraggableFlatList
        style={{ marginBottom: 70 }}
        keyExtractor={(item) => item?.id?.toString()}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        onDragEnd={({ data, from, to }) => updateSorts(data, from, to)}
        data={sortedLiteEntityTypes}
        renderItem={({ item, drag }) => (
          <ListItemLinkButton
            title={item.label}
            onLongPress={drag}
            to={
              `/settings/admin-tools/manage-entity-types/${item.id}` +
              '?from=' +
              encodeURIComponent(
                '/settings/admin-tools/manage-entity-types/index'
              )
            }
            avatarIcon={getEntityTypeIcon(item)}
          />
        )}
      />
    </WithHeader>
  )
}
