import _ from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PublicFormContext from '../../contexts/PublicFormContext'
import { flattenEntity } from '../../helpers/entity'
import { sanitiseFilterString } from '../../libraries/sanitiseFilterString'

//actions
import entityActions, { getFilterString } from '../../store/entity/actions'
import { evaluateRule } from '../query/evaluateRule'

import CheckboxMultiselectWithData from './CheckboxMultiselectWithData'
import { shouldRefetch } from './RadioList'

export const CheckboxMultiselect = (props) => {
  const dispatch = useDispatch()

  const [nextUnfetchedPage, setNextUnfetchedPage] = useState(1)
  const publicFormContext = useContext(PublicFormContext)

  const sourceNames = _.castArray(props.data.field.field_data.params.sourceName)

  const filterRule =
    props?.data?.field?.field_data?.params?.optionsFilter?.json?.logic
  const filter = filterRule ? { detailed: true } : null

  const filterString = sanitiseFilterString(getFilterString(null, filter))

  const sourceListRoots = sourceNames.map((sourceName) => {
    return useSelector((state) =>
      sourceName
        ? _.get(state.entities, `[${sourceName + filterString}]`, {
            data: []
          })
        : []
    )
  })

  const fetchNextPage = (index, page) => {
    dispatch(
      entityActions.listEntity(
        { name: sourceNames[index] },
        { filter, page },
        undefined,
        publicFormContext.entityType,
        {
          tenantId: publicFormContext.tenantId,
          tenantHmac: publicFormContext.tenantHmac
        }
      )
    ).then((response) => {
      if (response.meta.current_page < response.meta.last_page) {
        setNextUnfetchedPage(response.meta.current_page + 1)
      } else {
        setNextUnfetchedPage(-1)
      }

      return response
    })
  }

  useEffect(() => {
    sourceListRoots.map((sourceListRoot, index) => {
      if (shouldRefetch(sourceListRoot)) {
        fetchNextPage(index, nextUnfetchedPage)
      }
    })
  }, [])

  useEffect(() => {
    if (nextUnfetchedPage !== -1) {
      fetchNextPage(0, nextUnfetchedPage)
    }
  }, [nextUnfetchedPage])

  const sourceData = useMemo(() => {
    const data = _.flatten(
      sourceListRoots.map((sourceListRoot) => {
        return _.get(sourceListRoot, 'data', [])
      })
    )
    const filteredData = filterRule
      ? data.filter((d) =>
          evaluateRule(filterRule, {
            ...flattenEntity(d, false),
            field: flattenEntity(props.data.entity, false)
          })
        )
      : data

    return filteredData
  }, [sourceListRoots])

  const groupByFunction =
    sourceListRoots.length > 1
      ? (f) => {
          return f.entity_type.label
        }
      : undefined

  return (
    <CheckboxMultiselectWithData
      {...props}
      dataRows={sourceData}
      groupByFunction={groupByFunction}
      labelField={'display_name'}
      value={props.defaultValue}
    />
  )
}
