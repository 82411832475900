import _ from 'lodash'

// property map.

// {
//   name: 'Single File',
//   type: 'single-file',
//   renders: 'single-file',
//   isFile: true,
//   canRequire: false,
//   canBeCreateOnly: false,
//   validatesAs: "string",
//  canAssignToEntityType: false,
//   alwaysHorizontal: true, //always renders as a horizontal field.
//   supportsInputShading: false, //should have a gray box
//   validations: {
//     max: 255
//   }
// },

export const supportedFieldTypes = _.sortBy(
  [
    {
      name: 'Approval Required',
      type: 'approval-required',
      renders: 'approval-required',
      returnsRichObject: true,
      shouldHaveValue: true,
      queryFieldValueType: 'boolean' //this is a rich object, and 'value' will be of type 'boolean'
    },
    {
      name: 'Full Address NFIRS',
      type: 'full-address-nfirs',
      renders: 'full-address-nfirs'
    },
    {
      name: 'Personnel Select',
      type: 'select-personnel',
      renders: 'select-personnel',
      returnsRichObject: true,
      canScanBarcode: false
    },
    {
      name: 'Role Multi Select',
      type: 'select-roles',
      renders: 'select-roles',
      returnsRichObject: true,
      canScanBarcode: false,
      canSelectMultiple: true
    },
    {
      name: 'Role Select',
      type: 'select-role',
      renders: 'select-role',
      returnsRichObject: true,
      canScanBarcode: false
    },
    {
      name: 'Dynamic Text (Fields as Source)',
      type: 'dynamic-text-with-fields',
      renders: 'dynamic-text-with-fields',
      returnsRichObject: true,
      canAssignToEntityType: false,
      canScanBarcode: false
    },
    {
      name: 'Icon Picker',
      type: 'icon-picker',
      renders: 'icon-picker',
      canScanBarcode: false
    },
    {
      name: 'Button',
      type: 'field-button',
      renders: 'field-button',
      canScanBarcode: false,
      supportsInputShading: false
    },
    {
      name: 'Widget Entity',
      type: 'widget-entity',
      renders: 'widget-entity'
    },
    {
      name: 'Admin Personnel Select',
      type: 'select-admin-personnel',
      renders: 'select-admin-personnel',
      returnsRichObject: true,
      canScanBarcode: false
    },
    {
      name: 'Tenant Select',
      type: 'select-tenant',
      renders: 'select-tenant',
      returnsRichObject: true,
      canAssignToEntityType: false,
      canScanBarcode: false
    },
    {
      name: 'Tenant Select (Multiple)',
      type: 'select-tenant-multiple',
      renders: 'select-tenant-multiple',
      returnsRichObject: true,
      canAssignToEntityType: false,
      canScanBarcode: false,
      canSelectMultiple: true
    },

    {
      name: 'Dropdown',
      type: 'select-list',
      renders: 'select-list',
      shouldHaveValue: true,
      returnsRichObject: true,
      showClear: true,
      clearValue: null,
      canScanBarcode: false
    },
    {
      name: 'Select Entity Type',
      type: 'select-entity-type',
      renders: 'select-entity-type',
      shouldHaveValue: true,
      returnsRichObject: true,
      canAssignToEntityType: true,
      canScanBarcode: false
    },
    {
      name: 'Color Picker',
      type: 'color-picker',
      renders: 'color-picker',
      canScanBarcode: false,
      returnsRichObject: false
    },
    {
      name: 'Query Logic',
      type: 'query-logic',
      renders: 'query-logic',
      canScanBarcode: false,
      canAssignToEntityType: 'global-only',
      returnsRichObject: true
    },
    {
      name: 'Dropdown Multi-Select (List)',
      type: 'select-list-multiple',
      renders: 'select-list-multiple',
      shouldHaveValue: true,
      returnsRichObject: true,
      canScanBarcode: false,
      canSelectMultiple: true
    },
    {
      name: 'Radio List Select',
      type: 'radio-list',
      renders: 'radio-list',
      returnsRichObject: true,
      canScanBarcode: false
    },
    {
      name: 'Matrix Field',
      type: 'matrix-field',
      renders: 'matrix-field',
      isMatrix: true,
      canScanBarcode: false
    },
    {
      name: 'Link To',
      type: 'field-link-to',
      renders: 'field-link-to',
      canScanBarcode: false
    },
    {
      name: 'Dropdown (Enter Values)',
      type: 'select-adhoc',
      renders: 'select-adhoc',
      shouldHaveValue: true,
      returnsRichObject: true,
      canScanBarcode: false
    },
    {
      name: 'Radio List Select (Enter Values)',
      type: 'radio-adhoc',
      renders: 'radio-adhoc',
      shouldHaveValue: true,
      returnsRichObject: true,
      canScanBarcode: false
    },
    {
      name: 'Checkbox Multi-Select (Enter Values)',
      type: 'checkbox-multiselect-adhoc',
      renders: 'checkbox-multiselect-adhoc',
      returnsRichObject: true,
      canScanBarcode: false,
      canSelectMultiple: true
    },

    {
      name: 'Checkbox Multi-Select (List)',
      type: 'checkbox-multiselect',
      renders: 'checkbox-multiselect',
      shouldHaveValue: true,
      returnsRichObject: true,
      canScanBarcode: false,
      canSelectMultiple: true
    },
    {
      name: 'Date Picker',
      type: 'date-picker',
      renders: 'date-picker',

      showClear: true,
      clearValue: '',
      canScanBarcode: false
    },

    {
      name: 'Date Time Picker',
      type: 'date-time-picker',
      showClear: true,
      clearValue: '',
      renders: 'date-time-picker',
      canScanBarcode: false
    },

    {
      name: 'Time Picker',
      type: 'time-picker',
      renders: 'time-picker',
      canScanBarcode: false
    },
    {
      name: 'View List',
      type: 'view-list',
      renders: 'view-list',
      canRequire: false,
      canBeCreateOnly: false,
      shouldHaveValue: true,
      canScanBarcode: false
    },
    {
      name: 'View List Inline',
      type: 'view-list-inline',
      renders: 'view-list-inline',
      canRequire: false,
      canBeCreateOnly: false,
      shouldHaveValue: true,
      canScanBarcode: false
    },
    {
      name: 'Select Filters',
      type: 'select-filters',
      renders: 'select-filters'
    },
    {
      name: 'Field Select',
      type: 'select-field',
      renders: 'select-field',
      canAssignToEntityType: true,
      shouldHaveValue: true,
      canScanBarcode: false
    },
    {
      name: 'State Select',
      type: 'select-state',
      renders: 'select-state',
      canScanBarcode: false
    },
    {
      name: 'Hidden',
      type: 'hidden',
      renders: 'hidden',
      canScanBarcode: false
    },
    {
      name: 'Formula Result',
      type: 'formula-result',
      renders: 'formula-result',
      canHaveFormulaApplied: true,
      canScanBarcode: false
    },
    {
      name: 'Formula Result (Roll-up)',
      type: 'formula-result-rollup',
      renders: 'formula-result',
      canHaveFormulaApplied: false,
      canScanBarcode: false
    },
    {
      name: 'Text with Mask',
      type: 'single-text-mask',
      renders: 'single-text-mask',
      canScanBarcode: true,
      validatesAs: 'string',
      showClear: true,
      clearValue: '',
      validations: {
        max: 255
      }
    },
    {
      name: 'Text (Single Line)',
      type: 'single-text',
      renders: 'single-text-mask',
      canScanBarcode: true,
      validatesAs: 'string',
      showClear: true,
      validations: {
        max: 255
      }
    },
    {
      name: 'Text Area',
      type: 'textarea',
      renders: 'textarea',
      validatesAs: 'string',
      canScanBarcode: false,
      showClear: true,
      validations: {
        max: 65536
      }
    },
    {
      name: 'Email',
      type: 'email',
      renders: 'email'
    },
    {
      name: 'Boolean',
      type: 'boolean',
      renders: 'boolean',
      alwaysHorizontal: true,
      supportsInputShading: false, //should have a gray box
      canRequire: false
    },
    {
      name: 'Phone',
      type: 'phone',
      showClear: true,
      renders: 'phone'
    },
    {
      name: 'ZIP Code',
      type: 'zip-code',
      showClear: true,
      renders: 'text'
    },
    {
      name: 'File Upload',
      type: 'single-file',
      renders: 'single-file',
      isFile: true
    },
    {
      name: 'Signature Pad',
      type: 'signature-pad',
      renders: 'signature-pad',
      isFile: true
    },
    {
      name: 'Year',
      type: 'year-entry',
      renders: 'year-entry',
      canScanBarcode: false
    },
    {
      name: 'Number Slider',
      type: 'number-slider',
      renders: 'number-slider',
      canScanBarcode: false
    },
    {
      name: 'Number Entry',
      type: 'number-entry',
      renders: 'number-entry',
      canScanBarcode: false
    },
    {
      name: 'Number Counter',
      type: 'number-counter',
      renders: 'number-counter',
      canScanBarcode: false
    },
    {
      name: 'Star Rating',
      type: 'star-rating',
      renders: 'star-rating',
      canScanBarcode: false
    },
    {
      name: 'Row Container',
      type: 'container-row',
      renders: 'container-row'
    },
    {
      name: 'Group Container',
      type: 'container-group',
      renders: 'container-group',
      disallowNestingInContainer: true
    },
    {
      name: 'Quiz Question Container',
      type: 'quiz-question-group',
      renders: 'quiz-question-group',
      isContainer: true
    },
    {
      name: 'Quiz - True/False',
      type: 'true-false-quiz',
      renders: 'true-false-quiz',
      shouldHaveValue: true,
      returnsRichObject: true,
      canScanBarcode: false
    },
    {
      name: 'Quiz - Multiple Choice - Single Answer',
      type: 'radio-quiz',
      renders: 'radio-quiz',
      shouldHaveValue: true,
      returnsRichObject: true,
      canScanBarcode: false
    },
    {
      name: 'Quiz - Multiple Choice - Multiple Answer',
      type: 'checkbox-quiz',
      renders: 'checkbox-quiz',
      returnsRichObject: true,
      canScanBarcode: false
    },
    {
      name: 'Tile Container',
      type: 'container-tile',
      renders: 'container-tile',
      isContainer: true
    },
    {
      name: 'Button Container',
      type: 'container-button',
      renders: 'container-button',
      isContainer: true,
      supportsInputShading: false
    },
    {
      name: 'Terms and Conditions',
      type: 'terms-and-conditions',
      renders: 'terms-and-conditions'
    },
    {
      name: 'Rich Text (Static)',
      type: 'description-rich-text',
      renders: 'description-rich-text'
    },
    {
      name: 'Rich Text Area',
      type: 'rich-text-area',
      renders: 'rich-text-area',
      defaultValueCanBeUpdated: false,
      validatesAs: 'string'
    },
    {
      name: 'Label',
      type: 'text-label',
      renders: 'text-label',
      canAssignToEntityType: false,
      supportsInputShading: false //should have a gray box
    },
    {
      name: 'Yes/No',
      type: 'yes-no',
      renders: 'yes-no',
      alwaysHorizontal: true,
      canHaveStaticHeader: true
    },
    {
      name: 'Heading',
      type: 'heading',
      renders: 'heading'
    },
    {
      name: 'Column Break',
      type: 'column-break',
      renders: 'column-break'
    },
    {
      name: 'Full Name Entry',
      type: 'full-name-entry',
      renders: 'full-name-entry'
    },
    {
      name: 'Location (Latitude / Longitude entry)',
      type: 'lat-lng',
      renders: 'lat-lng'
    },
    {
      name: 'Custom Functionality',
      type: 'custom-functionality',
      renders: 'custom-functionality'
    }
  ],
  (el) => el.name
)
