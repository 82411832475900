import Mustache from 'mustache'
import { flattenEntity } from '../helpers/entity'
import { useEffect, useMemo, useState } from 'react'
import { evaluate } from '../helpers/jsonlogic'
import _ from 'lodash'
import { DateTime, Duration } from 'luxon'
import { Interpreter } from 'js-interpreter-npm'
import { useSelector } from 'react-redux'
import { DATE_FORMAT } from '../components/fields/DatePicker'

export const fieldIsAffectedByPageVisibilityRules = (
  field,
  pageVisibilityRules
) => {
  const pageOfField = field.field_data?.page ?? 1
  if (pageVisibilityRules[pageOfField] !== undefined) {
    return !pageVisibilityRules[pageOfField]
  }
  return false
}
export const getFormulaBasedChanges = (entityFlattened, entityType) => {
  if (!entityType) {
    return []
  }
  // check for any formulas in the entity type.
  // calculate the outcomes if so.
  const fieldsWithFormulas = (entityType?.fields ?? []).filter((f) =>
    _.get(f, 'field_data.params.contentsFormula', null)
  )
  let updates = []
  Mustache.escape = function (text) {
    return text
  }
  fieldsWithFormulas.map((field) => {
    const contentsFormula = _.get(
      field,
      'field_data.params.contentsFormula',
      ''
    )

    const expression = Mustache.render(`${contentsFormula}`, {
      ...entityFlattened
    })

    try {
      const interpreter = new Interpreter(expression)

      interpreter.run()
      let result = interpreter.value
      const postFormatter = _.get(
        field,
        'field_data.params.formatResultAs',
        false
      )

      switch (postFormatter) {
        case 'duration':
          {
            const duration = Duration.fromMillis(result)
            result = duration.toFormat('hh:mm:ss')
          }
          break

        case 'date': {
          if (result) {
            const dt = DateTime.fromJSDate(result.data)
            result = dt.toFormat(DATE_FORMAT)
          }
          break
        }
      }
      updates.push({ field, result })
    } catch (e) {
      const logEvaluationErrors = true
      logEvaluationErrors &&
        console.warn('Evaluation error', expression, e.message)
    }
  })
  return updates
}

export const actionRulesetIsTruthy = (action, rulesetEntity) => {
  const ruleset = _.get(action, 'ruleset_json.logic', null)

  let result = false

  try {
    result = evaluate(ruleset, rulesetEntity)
  } catch {}

  return result
}

const applyRules = (
  _action,
  _entityType,
  isTruthy,
  updateFieldVisibilityPartial,
  updatePageVisibilityPartial,
  changesRequiredFromEffects = []
) => {
  _action.effects.forEach((effect) => {
    switch (effect.type) {
      case 'show-fields':
        _.get(effect, 'fields.list', []).map((field) => {
          // if the rule is truthy, hide the field.
          // if rule is not truthy, then show it.
          updateFieldVisibilityPartial[field.field_data.property] = isTruthy
        })
        break
      case 'hide-fields':
        _.get(effect, 'fields.list', []).map((field) => {
          // if the rule is truthy, hide the field.
          // if rule is not truthy, then show it.
          updateFieldVisibilityPartial[field.field_data.property] = !isTruthy
        })
        break
      case 'hide-pages':
        _.get(effect, 'pages.list', []).map(({ id: page }) => {
          const affectedFields = _.get(_entityType, 'fields', []).filter(
            (f) => f.field_data.page === page
          )
          affectedFields.map(
            (field) =>
              (updateFieldVisibilityPartial[
                field.field_data.property
              ] = !isTruthy)
          )
          updatePageVisibilityPartial[page] = !isTruthy
        })
        break
      case 'show-pages':
        _.get(effect, 'pages.list', []).map(({ id: page }) => {
          const affectedFields = _.get(_entityType, 'fields', []).filter(
            (f) => f.field_data.page === page
          )
          affectedFields.map(
            (field) =>
              (updateFieldVisibilityPartial[
                field.field_data.property
              ] = isTruthy)
          )
          updatePageVisibilityPartial[page] = isTruthy
        })
        break
      case 'setPropertyClient':
        if (isTruthy) {
          ;(effect.setPropertyClient?.property ?? []).map(
            ({ value, source }) => {
              changesRequiredFromEffects.push({
                field: source,
                result: value
              })
            }
          )
        }
        break
    }
  })
}

export const useDoVisibilityChecks = ({
  entity,
  entityType,
  entityChanged = null,
  publicFormContext = null
}) => {
  const { current_user } = useSelector((state) => {
    return {
      current_user: state.users?.user
    }
  })

  const [fieldVisibilityRules, setFieldVisibilityRules] = useState({})
  const [pageVisibilityRules, setPageVisibilityRules] = useState({})

  const entityFlattened = useMemo(() => {
    // also matches withObjectDataFlat in Entity.php
    const entityFlattened = flattenEntity(entity, false)

    return entityFlattened
  }, [entity])
  useEffect(() => {
    // corresponds to ProcessEntityPostSave:90

    const updateFieldVisibilityPartial = {}
    const updatePageVisibilityPartial = {}
    const changesRequiredFromEffects = []

    if (entity && entityType && entityType.actions) {
      _.get(entityType, 'actions', []).map((a) => {
        const isTruthy = actionRulesetIsTruthy(a, entityFlattened)

        applyRules(
          a,
          entityType,
          isTruthy,
          updateFieldVisibilityPartial,
          updatePageVisibilityPartial,
          changesRequiredFromEffects
        ) //may show or hide fields, show or hide pages
      })
    }

    // apply public form field visibility.
    if (entityType && publicFormContext?.public) {
      entityType.fields.forEach((f) => {
        const hide_when_public_form =
          f.field_data?.params?.hide_when_public_form ?? false

        if (hide_when_public_form) {
          updateFieldVisibilityPartial[f.field_data?.property] = false
        }
      })
    }

    if (entityType && entityType.object_data?.page_visibility_rules) {
      for (
        let i = 0;
        i < entityType.object_data?.page_visibility_rules.length;
        i++
      ) {
        if (entityType.object_data?.page_visibility_rules[i]) {
          let workingRules = entityType.object_data?.page_visibility_rules[i]

          // working rules is a list of roles that this page can be visible to.
          // TODO: Make sure we capture 'ALL'.
          const actingRole = current_user.acting_role
          const workingRulesRoles = workingRules.map((r) => r.key)
          if (
            workingRulesRoles.includes(actingRole) ||
            workingRulesRoles.includes('ALL')
          ) {
            updatePageVisibilityPartial[i + 1] = true
          } else {
            updatePageVisibilityPartial[i + 1] = false
          }
        }
      }
    }

    setFieldVisibilityRules(updateFieldVisibilityPartial)
    setPageVisibilityRules(updatePageVisibilityPartial)

    let changes = getFormulaBasedChanges(entityFlattened, entityType)

    changes = [...changes, ...changesRequiredFromEffects]

    if (changes.length) {
      let newEntityPartial = {}
      let hasChangesToApply = false

      changes.map(({ field, result }) => {
        if (entity[field.field_data.property] != '' + result) {
          newEntityPartial[field.field_data.property] = '' + result
          hasChangesToApply = true
        }
      })

      if (hasChangesToApply) {
        entityChanged?.(newEntityPartial, false)
      }
    }
  }, [entity, entityType, publicFormContext?.public])

  return { fieldVisibilityRules, pageVisibilityRules }
}
