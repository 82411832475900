import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { Overlay } from 'react-native-elements'
import { ScrollView } from 'react-native-gesture-handler'
import spacing from '../styles/spacing'
import { DatePicker } from './fields/DatePicker'
import MSFESButton from './MSFESButton'
import MSFESInput from './MSFESInput'
import OverlayHeader from './OverlayHeader'
import _ from 'lodash'
import MSFESAccordion from './MSFESAccordion'
import { withBorderStyle } from './MSFESFieldTile'
import { useDispatch, useSelector } from 'react-redux'
import { upsertEntity } from '../store/entity/actions'
import { simplifyObject } from './fields/SelectWithData'
import { showMessage } from 'react-native-flash-message'
import { simplifyFilters } from '../screens/EntityListScreen'
import { SelectListMultiple } from './fields/SelectListMultiple'
import { SelectTenant } from './fields/SelectTenant'
import { useTenantOrGlobalAdmin } from '../hooks/useTenantOrGlobalAdmin'
import SelectMultipleWithData from './fields/SelectMultipleWithData'
import { SelectMultipleFields } from './fields/SelectMultipleFields'
import { WrappedSwitch } from './EntityField'
import { supportedFieldTypes } from '../libraries/supportedFieldTypes'

const SaveFilterAsReportAccordion = (props) => {
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)

  const { isGlobalAdmin } = useTenantOrGlobalAdmin()
  const dispatch = useDispatch()
  const { entityTypes } = useSelector((appState) => ({
    entityTypes: appState.entityTypes?.dataLite?.data
  }))
  const entityType = useMemo(() => {
    return entityTypes.find((e) => e.name === 'system_report')
  }, [entityTypes])
  const { filter, filterEntityType, filtersAvailable } = props

  const [title, setTitle] = useState('')
  const [tenant, setTenant] = useState(undefined)

  const [viewOnly, setViewOnly] = useState(false)
  const [reportFields, setReportFields] = useState(undefined)

  const isMultiselectField = (field) => {
    const fieldMatch = supportedFieldTypes.find(
      (f) => f.type === field.field_data?.type
    )
    return fieldMatch?.canSelectMultiple == true
  }
  const [reportMultiselectFields, setReportMultiselectFields] = useState(
    undefined
  )
  const createReportFromFilter = () => {
    const entity = {
      title,
      filter_body: JSON.stringify(
        simplifyFilters({}, filter, filtersAvailable)
      ),
      tenant_id: tenant ?? undefined,
      report_entity_type: simplifyObject(filterEntityType),
      report_fields: reportFields,
      report_multiselect_fields: reportMultiselectFields,
      view_only_no_click_through: viewOnly
    }

    setLoading(true)
    // TODO: Loading spinner, success message
    dispatch(upsertEntity(entity, entityType, 'add'))
      .then(() => {
        setSaved(true)
        showMessage({ message: 'Saved query as report.', type: 'success' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <MSFESAccordion title="Save as Report" id="report">
      <View style={{ ...withBorderStyle, padding: spacing.m1 }}>
        <MSFESInput
          horizontal
          label={'Report Title'}
          onChangeText={(newTitle) => setTitle(newTitle)}
          placeholder={`Report Title`}
          value={title}
        />

        {!!isGlobalAdmin && (
          <MSFESInput
            horizontal
            label="For Client"
            InputComponent={SelectTenant}
            defaultValue={tenant}
            onChangeText={(tenant) => {
              setTenant(tenant)
            }}
          />
        )}
        <MSFESInput
          horizontal
          label={'View Only (No Click Through)'}
          InputComponent={WrappedSwitch}
          placeholder={`Report Title`}
          value={viewOnly}
          onValueChange={() => {
            setViewOnly(!viewOnly)
          }}
        />
        {!!isGlobalAdmin && (
          <MSFESInput
            horizontal
            label="Fields in View"
            InputComponent={SelectMultipleFields}
            fieldsSupplied={filterEntityType.fields}
            value={reportFields}
            changeHandler={(reportFields) => {
              setReportFields(reportFields)
            }}
          />
        )}
        {!!isGlobalAdmin && (
          <MSFESInput
            horizontal
            label="Expand Multiselect Fields" /* If user has selected multiple 'incidents attended', then outputting a personnel record will output a row for each incidents_attended entry. */
            InputComponent={SelectMultipleFields}
            fieldsSupplied={filterEntityType.fields.filter(
              (f) =>
                reportFields?.some(
                  (rf) => rf.field_data?.property === f.field_data?.property
                ) && isMultiselectField(f)
            )}
            value={reportMultiselectFields}
            changeHandler={(reportMultiselectFields) => {
              setReportMultiselectFields(reportMultiselectFields)
            }}
          />
        )}

        <MSFESButton
          modifier="outline"
          loading={loading}
          disabled={saved}
          title={'Save as Report'}
          containerStyle={{ flex: 1 }}
          onPress={createReportFromFilter}
        />
      </View>
    </MSFESAccordion>
  )
}
export const FiltersConfigurator = ({
  filtersApplied = {},
  onBackdropPress,
  filtersAvailable = [],
  onFiltersChanged,
  filterEntityType = null
}) => {
  const [localFiltersApplied, setLocalFiltersApplied] = useState({})

  useEffect(() => {
    setLocalFiltersApplied(filtersApplied)
  }, [])

  useEffect(() => {
    //  onFiltersChanged(localFiltersApplied) //bubble up happens on apply.
  }, [localFiltersApplied])

  const clearFilters = (property) => {
    setLocalFiltersApplied((filtersApplied) => {
      filtersApplied[property] = null
      return { ...filtersApplied }
    })
  }
  const changeDate = (property, sideOfDate, newDate) => {
    // sideOfDate = "from", "to"
    setLocalFiltersApplied((filtersApplied) => {
      if (_.get(newDate, 'display_value', false) === null) {
        delete filtersApplied[property]
      } else {
        if (!filtersApplied[property]) {
          filtersApplied[property] = {}
        }
        filtersApplied[property][sideOfDate] = newDate.display_value
      }
      return { ...filtersApplied }
    })
  }

  return (
    <Overlay visible={true} onBackdropPress={onBackdropPress}>
      <OverlayHeader>Apply Filters</OverlayHeader>
      <View style={{ maxHeight: 400, minWidth: 200 }}>
        <ScrollView>
          {filtersAvailable
            .filter((f) => f.filterType == 'dropdown')
            .map((f) => {
              return (
                <View key={f.id} style={{ marginLeft: spacing.m2 }}>
                  <MSFESInput
                    label={f.field_data.title}
                    InputComponent={SelectListMultiple}
                    data={{ field: f }}
                    defaultValue={localFiltersApplied[f.field_data.property]}
                    onChangeText={(newValue) => {
                      setLocalFiltersApplied((filtersApplied) => {
                        if (_.get(newValue, 'id', false) === null) {
                          delete filtersApplied[f.field_data.property]
                        } else {
                          filtersApplied[f.field_data.property] = newValue
                        }
                        return { ...filtersApplied }
                      })
                    }}
                  />
                </View>
              )
            })}
          {filtersAvailable
            .filter((f) => f.filterType == 'dropdown-adhoc')
            .map((f) => {
              return (
                <View key={f.id} style={{ marginLeft: spacing.m2 }}>
                  <MSFESInput
                    label={f.field_data.title}
                    InputComponent={SelectMultipleWithData}
                    dataRows={f.field_data?.params?.options}
                    keyField={'key'}
                    labelField={'label'}
                    value={localFiltersApplied[f.field_data.property]}
                    defaultValue={localFiltersApplied[f.field_data.property]}
                    onChangeText={(newValue) => {
                      setLocalFiltersApplied((filtersApplied) => {
                        if (_.get(newValue, 'id', false) === null) {
                          delete filtersApplied[f.field_data.property]
                        } else {
                          filtersApplied[f.field_data.property] = newValue
                        }
                        return { ...filtersApplied }
                      })
                    }}
                  />
                </View>
              )
            })}

          {filtersAvailable
            .filter((f) => f.filterType == 'dropdown-boolean')
            .map((f) => {
              return (
                <View key={f.id} style={{ marginLeft: spacing.m2 }}>
                  <MSFESInput
                    label={f.field_data.title}
                    InputComponent={SelectMultipleWithData}
                    dataRows={[
                      { key: 'true', label: 'YES' },
                      { key: 'false', label: 'NO' }
                    ]}
                    keyField={'key'}
                    labelField={'label'}
                    value={localFiltersApplied[f.field_data.property]}
                    defaultValue={localFiltersApplied[f.field_data.property]}
                    onChangeText={(newValue) => {
                      setLocalFiltersApplied((filtersApplied) => {
                        if (_.get(newValue, 'id', false) === null) {
                          delete filtersApplied[f.field_data.property]
                        } else {
                          filtersApplied[f.field_data.property] = newValue
                        }
                        return { ...filtersApplied }
                      })
                    }}
                  />
                </View>
              )
            })}
          {filtersAvailable
            .filter((f) => f.filterType == 'date')
            .map((f) => {
              const resetDisabled = !(
                localFiltersApplied[f.field_data.property]?.from ||
                localFiltersApplied[f.field_data.property]?.to
              )
              return (
                <View key={f.id} style={{ marginLeft: spacing.m2 }}>
                  <MSFESInput
                    label={f.field_data.title}
                    InputComponent={(props) => {
                      return (
                        <View style={props.style}>
                          <DatePicker
                            data={{ field: {} }}
                            editable={true}
                            value={
                              localFiltersApplied[f.field_data.property]?.from
                            }
                            datePlaceholder={'Select From Date'}
                            changeHandler={(newVal) => {
                              changeDate(f.field_data.property, 'from', newVal)
                            }}
                          />
                          <DatePicker
                            data={{ field: {} }}
                            editable={true}
                            value={
                              localFiltersApplied[f.field_data.property]?.to
                            }
                            datePlaceholder={'Select To Date'}
                            changeHandler={(newVal) => {
                              changeDate(f.field_data.property, 'to', newVal)
                            }}
                          />
                          <MSFESButton
                            disabled={resetDisabled}
                            onPress={() => {
                              clearFilters(f.field_data.property)
                            }}
                            style={{ marginRight: spacing.m2 }}
                            modifier="outline"
                            title={'Remove Filter'}
                          ></MSFESButton>
                        </View>
                      )
                    }}
                  />
                </View>
              )
            })}
        </ScrollView>
      </View>

      <View>
        <SaveFilterAsReportAccordion
          filtersAvailable={filtersAvailable}
          filter={localFiltersApplied}
          filterEntityType={filterEntityType}
        />
      </View>

      <View>
        <MSFESButton
          modifier="outline"
          disabled={!Object.keys(localFiltersApplied).length}
          title={'Clear Filters'}
          containerStyle={{ flex: 1, marginTop: spacing.m0_5 }}
          onPress={() => {
            setLocalFiltersApplied({})

            onFiltersChanged({})
          }}
        />
      </View>
      <View
        style={{ flexDirection: 'row', marginTop: spacing.m1, width: '100%' }}
      >
        <MSFESButton
          modifier="outline"
          containerStyle={{ flex: 1, marginRight: spacing.m0_5 }}
          title={'Cancel'}
          onPress={() => {
            onBackdropPress()
          }}
        />
        <MSFESButton
          title={'Apply'}
          containerStyle={{ flex: 1 }}
          onPress={() => {
            onFiltersChanged(localFiltersApplied)
            onBackdropPress()
          }}
        />
      </View>
    </Overlay>
  )
}
