import React, { useEffect, useState } from 'react'
import { View, Text } from 'react-native'
import { useStore } from 'react-redux'
import MSFESButton from '../MSFESButton'
import QueryBuilder from '../query/QueryBuilder'
import { useDiscoverEntityEntityType } from '../../hooks/useDiscoverEntityEntityType'
const QueryEditorField = (props) => {
  const [localValue, setLocalValue] = useState(null)
  const [renderKey, setRenderKey] = useState(0)
  const [hasChanges, setHasChanges] = useState(false)

  useEffect(() => {
    if (props.defaultValue && !localValue) {
      setLocalValue(props.defaultValue)
      setRenderKey(renderKey + 1)
    }
  }, [props.defaultValue])

  const store = useStore()

  const entityTypeShort =
    props.data.entity?.entity_type_target ||
    props.data.field?.field_data?.params?.entityTypeTarget

  const {
    entityType: entityTypeSelected,
    isFetching
  } = useDiscoverEntityEntityType(
    null,
    null,
    entityTypeShort?.name ?? entityTypeShort,
    entityTypeShort?.id
  )

  const onRulesChanged = (newRules) => {
    // props.changeHandler(newRules);
    setLocalValue(newRules)
    setHasChanges(true)
  }

  const saveChanges = () => {
    props.changeHandler(localValue)
    setHasChanges(false)
  }

  return (
    <View>
      {!props.editable && (
        <Text>
          Filter rules:{' '}
          {props.defaultValue
            ? 'A rule has been saved'
            : 'No rule has been saved'}
        </Text>
      )}
      {props.editable && (
        <View>
          {!entityTypeSelected && !isFetching && (
            <Text>Ensure an entity type is selected first.</Text>
          )}
          {!entityTypeSelected && isFetching && <Text>Loading...</Text>}
          <QueryBuilder
            entityType={entityTypeSelected}
            store={store}
            additionalFieldsForQueryEditor={
              props.additionalFieldsForQueryEditor
            }
            action={localValue ? { ruleset: localValue?.rules } : undefined}
            onQueryChanged={onRulesChanged}
          />

          <MSFESButton
            disabled={!hasChanges}
            variant="link"
            onPress={saveChanges}
            title="Save Changes"
          />
        </View>
      )}
    </View>
  )
}

export default QueryEditorField
