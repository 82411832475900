import { useDispatch, useSelector } from 'react-redux'
import { getEntityType } from '../store/entity-type/actions'
import { useEffect, useMemo, useState } from 'react'

// use the route and the entity to load and eventually return the entity type.
export const useDiscoverEntityEntityType = (
  route,
  entity,
  name = null,
  id = null,
  forceFetch = false
) => {
  const dispatch = useDispatch()
  const entityTypes = useSelector((AppState) => AppState.entityTypes.data)

  const [didFetch, setDidFetch] = useState(false)

  const [isFetching, setIsFetching] = useState(false)
  if (typeof route === 'string') {
    console.warn('Route should not be a string.')
  }

  const entityType = useMemo(() => {
    if (entity?.entity_type_id) {
      return entityTypes.find(
        (entityType) => entityType.id === entity.entity_type_id
      )
    }
    if (id) {
      return entityTypes.find((entityType) => entityType.id === id)
    }
    if (route) {
      return entityTypes.find((t) => t.name === route.params?.type)
    }
    if (name) {
      return entityTypes.find((t) => t.name === name)
    }
    console.warn(
      'useDiscoverEntityEntityType needs a route or an entity type ID or a name or an ID.'
    )
    return null
  }, [entityTypes, entity?.entity_type_id, route?.params?.type, name])

  useEffect(() => {
    if (!entityType) {
      return
    }
    setDidFetch(false)
  }, [entityType?.id])
  useEffect(() => {
    if (
      !entityType ||
      !entityType.fields ||
      forceFetch ||
      !entityTypes?.length
    ) {
      if (didFetch) {
        return
      }
      if (entity?.entity_type_id) {
        setDidFetch(true)
        setIsFetching(true)
        dispatch(
          getEntityType(entity?.entity_type_id, undefined, { read: true })
        ).finally(() => {
          setIsFetching(false)
        })
      }
      if (id) {
        setDidFetch(true)
        setIsFetching(true)
        dispatch(getEntityType(id, undefined, { read: true })).finally(() => {
          setIsFetching(false)
        })
      }
      if (route) {
        setDidFetch(true)
        setIsFetching(true)
        dispatch(
          getEntityType(route?.params?.type, undefined, { read: true })
        ).finally(() => {
          setIsFetching(false)
        })
      }
      if (name) {
        setDidFetch(true)
        setIsFetching(true)
        dispatch(getEntityType(name, undefined, { read: true })).finally(() => {
          setIsFetching(false)
        })
      }
    }
  }, [entityType?.id, id, name, forceFetch, didFetch, entityTypes?.length])

  return { entityType, isFetching, didFetch }
}
