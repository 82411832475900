import { useState } from 'react'

import { useDispatch } from 'react-redux'
import { listEntity } from '../../store/entity/actions'
import { openExternalLink } from '../../helpers/link'

export const useCanExportToCSV = ({ item, forceShowLabelInTable = false }) => {
  const [reportPreparing, setReportPreparing] = useState(false)
  const dispatch = useDispatch()
  const exportToCSV = () => {
    setReportPreparing(true)
    const fields =
      item.report_fields?.map((f) => f.field_data?.property) ?? undefined

    dispatch(
      listEntity(
        item.report_entity_type,
        {
          filter: JSON.parse(item.filter_body),
          detailed: true,
          fields,
          forceShowLabelInTable,
          fromSystemReportId: item.id
        },
        'xlsx'
      )
    )
      .then((response) => {
        openExternalLink(response.url)
      })
      .finally(() => {
        setReportPreparing(false)
      })
  }

  return { reportPreparing, exportToCSV }
}
