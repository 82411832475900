import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { View, Dimensions, ScrollView } from 'react-native'
import { withTheme } from 'react-native-elements'
import _ from 'lodash'
import spacing from '../styles/spacing'
import { EntityField, getFieldType } from './EntityField'

import Mustache from 'mustache'
import entityActions from '../store/entity/actions'

import { discoverSaveButtonLabel } from '../libraries/entityTools'

import { showMessage } from 'react-native-flash-message'
import { useSelector, useDispatch } from 'react-redux'

import WizardSteps, { useWizardSteps } from './WizardSteps'
import { EntityFieldSelectAssignee } from './EntityFieldSelectAssignee'
import { allKey } from './fields/MultipleRolePicker'
import CrossFieldReferencesContext from '../contexts/CrossFieldReferencesContext'
import CrossFieldRenderAsNullContext from '../contexts/CrossFieldRenderAsNullContext'
import actions from '../store/entity/actions'
import EntityEditContext from '../contexts/EntityEditContext'
import PublicFormContext from '../contexts/PublicFormContext'
import { useIsDesktop } from '../libraries/shouldShowDesktop'
import InPageValidationErrors from './InPageValidationErrors'
import { flattenEntity } from '../helpers/entity'
import MSFESHeading from './MSFESHeading'
import { ValidationAndButtonBar } from './ValidationAndButtonBar/ValidationAndButtonBar'
import { addMode } from '../screens/EntityAddComponent'
import { fieldIsAffectedByPageVisibilityRules } from '../hooks/useDoVisibilityChecks'

let partialUpdate = {}

let timeoutId = null

export const tileContainerTypes = ['container-tile']

export const buttonContainerTypes = ['container-button']

export const getRenderStaticHeader = (field, index, fields) => {
  const richFieldType = getFieldType(field)

  let renderStaticHeader = false
  if (richFieldType?.canHaveStaticHeader) {
    renderStaticHeader = true
    // and field above had a static header
    if (index > 0) {
      const prevField = fields[index - 1]
      const richFieldTypePrev = getFieldType(prevField)
      if (richFieldType.type === richFieldTypePrev.type) {
        renderStaticHeader = false //was prev.
      }
    }
  }

  return renderStaticHeader
}

export const EntityEdit = ({
  currentPage,
  onUpdatePage,
  mode,
  entity,
  entityChanged,
  definition,
  prepopulatedFields,
  onSaveEntityPressed,
  loading,
  isWizard = false,
  hideButtons = false,
  children,
  wizardStepsTotal = 1,
  wizardStep = 1,
  createLabel = null,
  fieldVisibilityRules = {},
  pageVisibilityRules = {},
  onApproveToggled = () => {},
  pendingApprovals = []
}) => {
  const tenants = useSelector((state) => {
    return _.get(state.tenants, 'tenants.data', [])
  })

  const firePrefills = (property, fieldValue) => {
    let propertyToUseForPrefill

    switch (property) {
      case 'tenant_id':
        propertyToUseForPrefill = 'tenant'
        break
      case 'assignees':
        propertyToUseForPrefill = 'assignee'
        break
      default:
        propertyToUseForPrefill = property
        break
    }
    definition.fields.map((f) => {
      let overwrite_field_value_with_template = _.get(
        f,
        'field_data.params.overwrite_field_value_with_template',
        null
      )

      const fill_with_value_when_empty = _.get(
        f,
        'field_data.params.fill_with_value_when_empty',
        null
      )

      if (fill_with_value_when_empty) {
        if (f.field_data.property !== property) {
          const currentValue = entity?.[property]
          if (currentValue == null || currentValue == undefined) {
            fieldChanged(f, fill_with_value_when_empty, false)
          }
        }
      }

      if (
        overwrite_field_value_with_template !== null &&
        overwrite_field_value_with_template !== undefined &&
        overwrite_field_value_with_template !== ''
      ) {
        const templateLower = overwrite_field_value_with_template.toLowerCase()

        if (templateLower == propertyToUseForPrefill) {
          // something else is using this field, use this new value over there.
          fieldChanged(f, fieldValue, true)
        } else if (
          templateLower.indexOf(propertyToUseForPrefill + '.') !== -1
        ) {
          // user is after a sub property of this object. E.g., person has selected "Mr Sample" for field 1. But designer is after "Mr Sample.Rank" (personnel rank)
          const retrieveProperty = templateLower.substr(
            templateLower.indexOf('.') + 1
          )
          let uriToRetrieve
          if (propertyToUseForPrefill == 'tenant') {
            const tenant = tenants.find((t) => t.id == fieldValue)
            const retrievedTenant = tenant?.tenant_record
            uriToRetrieve = '/entity/tenant/' + retrievedTenant?.id
          } else {
            if (_.isArray(fieldValue)) {
              uriToRetrieve = fieldValue[0].uri
            } else {
              uriToRetrieve = fieldValue.uri
            }
          }

          dispatch(actions.showEntity({ route: uriToRetrieve })).then(
            (response) => {
              const retrievedValue = _.get(
                response.data,
                retrieveProperty,
                null
              )
              fieldChanged(f, retrievedValue, true)
            }
          )
        }
      }
    })
  }
  const fieldChanged = (field, fieldValue, isUserInitiated = true) => {
    // don't mutate from parent
    const newEntity = {}

    const { property } = field.field_data
    // allows dot notation

    const oldValue = _.get(entity, property, null)

    if (!oldValue) {
      // we are adding a new value here.
      // if it's junk, early exit.
      if (fieldValue === '') {
        return
      }
      if (fieldValue === null) {
        return
      }
    }

    _.set(newEntity, property, fieldValue)

    firePrefills(property, fieldValue)

    if (!isUserInitiated) {
      _.set(partialUpdate, property, fieldValue)

      if (!timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
          entityChanged(partialUpdate, false)
          partialUpdate = {}
          timeoutId = null
        }, 50)
      }
    } else {
      entityChanged(newEntity, true)
    }
  }

  const fileAppended = useCallback(
    (fileId) => {
      if (fileId) {
        const related_files = _.get(entity, 'related_files', [])
        const exists = related_files.find((file) => file.id === fileId)

        if (!exists) {
          const newEntity = { related_files: [...related_files] }
          newEntity.related_files.push({ id: fileId })

          entityChanged(newEntity)
        }
      }
    },
    [entity, entityChanged]
  )

  const errors = useSelector((state) =>
    _.get(state.entities, 'errors.errors', null)
  )

  const generalError = useSelector((state) =>
    _.get(state.entities, 'errors.message', null)
  )

  const dispatch = useDispatch()

  const nextPage = () => {
    // find the next available page.
    const _currentPage = currentPage ?? 1
    let toPage = parseInt(_currentPage) + 1

    let hasVisibleFields = false

    hasVisibleFields = visibleFields.some((v) => v.field_data?.page == toPage)

    while (
      (pageVisibilityRules[toPage] === false || !hasVisibleFields) &&
      toPage < definition.pages
    ) {
      toPage += 1
      hasVisibleFields = visibleFields.some((v) => v.field_data?.page == toPage)
    }

    if (toPage > currentPage) {
      // set scroll position to top.
      updateScrollPosition?.(0)
    }
    onUpdatePage?.(toPage)
  }

  const prevPage = () => {
    const _currentPage = currentPage ?? 1
    let toPage = parseInt(_currentPage) - 1

    let hasVisibleFields = false

    hasVisibleFields = visibleFields.some((v) => v.field_data?.page == toPage)

    while (
      (pageVisibilityRules[toPage] === false || !hasVisibleFields) &&
      toPage > 0
    ) {
      toPage -= 1
      hasVisibleFields = visibleFields.some((v) => v.field_data?.page == toPage)
    }

    if (toPage < currentPage) {
      // set scroll position to top.
      updateScrollPosition?.(0)
    }
    onUpdatePage?.(toPage)
  }

  useEffect(() => {
    if (generalError) {
      showMessage({
        message: generalError,
        type: 'danger',
        hideOnPress: true,
        autoHide: false,
        floating: true,
        style: { marginTop: 40 }
      })

      // error has displayed. Can clear from store now.
      setTimeout(() => {
        dispatch(entityActions.clearGeneralError())
      }, 10000)
    }
  }, [generalError])

  const saveButtonLabel = discoverSaveButtonLabel(definition, mode, createLabel)

  const publicFormContext = useContext(PublicFormContext)

  const [
    ,
    ,
    updateScrollPosition,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    setVisibleFields
  ] = useContext(EntityEditContext)

  const { role, user_tenant_id } = useSelector((state) => {
    const personnel_record = _.get(state, 'users.user.personnel_record', null)
    return {
      role: _.get(personnel_record, 'role', 'Tenant User'),
      user_tenant_id: _.get(personnel_record, 'tenant_id', null)
    }
  })

  const isGlobalAdmin = role === 'MSFES User'

  // if this guy is not a global admin, fill the tenant_id for prefilling purposes.
  useEffect(() => {
    if (!isGlobalAdmin && user_tenant_id) {
      firePrefills('tenant_id', user_tenant_id)
    }
  }, [isGlobalAdmin, user_tenant_id])

  const quickAddFields = useMemo(() => {
    if (definition?.fields) {
      return (definition?.fields ?? [])
        .filter((f) => f.field_data?.flags?.quickAddField)
        .filter((f) => {
          const result = !fieldIsAffectedByPageVisibilityRules(
            f,
            pageVisibilityRules
          )
          return result
        })
    }
    return false
  }, [definition?.fields, pageVisibilityRules])

  const [requiresCaptchaFilled, setRequiresCaptchaFilled] = useState(false)
  useEffect(() => {
    if (publicFormContext.tenantId && publicFormContext.tenantHmac) {
      // we have received a tenant ID and hmac via a public form. Fill the entity with it.
      // server side will validate the tenant ID and hmac.
      fieldChanged(
        { field_data: { property: 'tenant_id' } },
        publicFormContext.tenantId,
        false
      )
      fieldChanged(
        { field_data: { property: 'tenant_id_hmac' } },
        publicFormContext.tenantHmac,
        false
      )
      setRequiresCaptchaFilled(true)
    }
  }, [publicFormContext.tenantId, publicFormContext.tenantHmac])

  const isInQuickAddMode =
    entity?.is_quick_mode && mode === addMode && !!quickAddFields.length

  const shouldPaginate = !isInQuickAddMode //mode === 'add'

  const visibleFields = (isInQuickAddMode ? quickAddFields : definition.fields)
    .filter((f) => {
      let permittedRoles = ['ALL']
      try {
        permittedRoles = _.get(f, 'field_data.permissions.visible_to', [
          allKey
        ]).map((r) => r.key ?? r)
      } catch (e) {
        // expect this to fire for records with old data style.
      }

      if (
        permittedRoles.includes('ALL') ||
        permittedRoles.includes(role) ||
        isGlobalAdmin
      ) {
        return true
      }

      return false
    })
    .filter((f) => {
      let visibilityRulesState = true

      // the field can have a default visibility parameter of false.
      if (_.get(f, 'field_data.params.visibility', true) === false) {
        visibilityRulesState = false
      } else {
        if (_.get(f, 'field_data.visibility', true) === false) {
          visibilityRulesState = false
        }
      }
      if (fieldVisibilityRules[f.field_data.property] !== undefined) {
        // can be true or false or undefined.
        visibilityRulesState = fieldVisibilityRules[f.field_data.property]
      }
      return visibilityRulesState
    })

  useEffect(() => {
    // pass up, to use on pagesdropdown & print screen, to hide pages with no available fields
    setVisibleFields && setVisibleFields(visibleFields)
  }, [visibleFields?.length])
  const fieldsInChildContainersToSkip = useMemo(() => {
    const fieldsInChildContainersToSkip = _.flatten(
      definition.fields
        .filter((f) => f.field_data.params && f.field_data.params.fieldList) //get fields with a fieldlist.
        .map((f) => f.field_data.params && f.field_data.params.fieldList) //return only the fieldlist
      // then flatten to a single array
    ).map((f) => {
      return f.offline_id ?? f.id
    }) //return only the IDs  in the array.

    // quick add has no child container concept
    if (isInQuickAddMode) {
      return []
    }

    return fieldsInChildContainersToSkip
  }, [definition, isInQuickAddMode])

  const fieldsWhichWillRenderInsideMatrix = useMemo(() => {
    return definition.fields
      .filter((f) => f.field_data?.belongs_to_matrix)
      .map((f) => f.id)
  }, [definition])

  const {
    availablePages,
    identifierCurrentPage,
    textLabelCurrentPage,
    onLastPageOfWizard,
    allPages
  } = useWizardSteps({
    isWizard,
    wizardStep,
    currentPage,
    definition,
    pageVisibilityRules,
    shouldPaginate,
    offline_id: entity?.offline_id
  })

  const [
    ,
    ,
    ,
    ,
    ,
    setAllPages,
    ,
    setCurrentPageIndex,
    ,
    setIsMobileOverlayVisible
  ] = useContext(EntityEditContext)

  useEffect(() => {
    setAllPages && setAllPages(allPages)
    setCurrentPageIndex && setCurrentPageIndex(identifierCurrentPage - 1)
  }, [allPages, identifierCurrentPage])

  const totalSteps = parseInt(wizardStepsTotal) - 1 + availablePages

  const hasNextStep = identifierCurrentPage < availablePages

  const width = Dimensions.get('window').width
  const { columnSets, maxWidth, columnsWide } = useMemo(() => {
    const columnSets = [[]]

    const orderedFilteredFields = _.chain(visibleFields)
      .filter(
        (f) =>
          !shouldPaginate ||
          f.field_data.page == currentPage ||
          !currentPage ||
          (typeof f.field_data.page == 'undefined' && currentPage == 1)
      )
      .filter((f) => !tileContainerTypes.includes(f.field_data.type))
      .filter((f) => !buttonContainerTypes.includes(f.field_data.type))
      .filter(
        (f) =>
          !fieldsInChildContainersToSkip.includes(f.id) &&
          !fieldsInChildContainersToSkip.includes(f.offline_id) &&
          !fieldsWhichWillRenderInsideMatrix.includes(f.id)
      )
      .sortBy((f) => _.get(f, 'field_data.sort_order', 999))
      .sortBy((f) => _.get(f, 'field_data.page', 1))

      .value()

    orderedFilteredFields.forEach((field) => {
      if (field.field_data?.type == 'column-break') {
        columnSets.push([])
      } else {
        columnSets[columnSets.length - 1].push(field)
      }
    })

    let maxWidth = '33.33%'
    let columnsWide = 3
    if (width < 1280 || columnSets.length == 2) {
      maxWidth = '50%'
      columnsWide = 2
    }
    // if (width < 1900 || columnSets.length == 3) {
    //   maxWidth = '33.33%'
    //   columnsWide = 3
    // }
    if (width < 769 || columnSets.length == 1) {
      maxWidth = '100%'
      columnsWide = 1
    }

    if (columnsWide == 1) {
      maxWidth = width > 500 ? 500 : width
    }
    return { columnSets, maxWidth, columnsWide }
  }, [visibleFields, width])

  const [crossFieldReferences, setCrossFieldReferences] = useState({})

  const [crossFieldRenderAsNull, setCrossFieldRenderAsNull] = useState({})

  const isDesktop = useIsDesktop()

  const formtitleValue = Mustache.render(
    `${definition?.object_data?.formtitle ?? ''}`,
    {
      ...flattenEntity(entity, false)
    }
  )

  const mobileBottomPadding = isDesktop ? null : { paddingBottom: 30 }

  const style = {
    flex: 1 / columnsWide,
    flexBasis: columnsWide == 1 ? '100%' : maxWidth,

    minWidth:
      columnsWide == 1 && width > 1024 ? 400 : width < 500 ? '100%' : 360,
    width: columnsWide == 1 && width > 1024 ? 700 : undefined,
    maxWidth: columnsWide == 1 && width > 1024 ? 700 : undefined,
    flexGrow: 0,
    flexShrink: 0,
    paddingHorizontal: spacing.m1
  }
  return (
    <>
      <CrossFieldRenderAsNullContext.Provider
        value={{ crossFieldRenderAsNull, setCrossFieldRenderAsNull }}
      >
        <CrossFieldReferencesContext.Provider
          value={{ crossFieldReferences, setCrossFieldReferences }}
        >
          <View style={[{ paddingTop: 0, flex: 1 }, mobileBottomPadding]}>
            {!!formtitleValue && !isDesktop && (
              <MSFESHeading
                style={{
                  justifyContent: 'center'
                }}
                textStyle={{ fontSize: 18 }}
              >
                {(formtitleValue ?? '').toUpperCase()}
              </MSFESHeading>
            )}
            {(shouldPaginate || isInQuickAddMode) && (
              <>
                {!!identifierCurrentPage && (
                  <WizardSteps
                    isInQuickAddMode={isInQuickAddMode}
                    step={identifierCurrentPage}
                    pageName={textLabelCurrentPage}
                    steps={totalSteps}
                    onPress={() => {
                      if (!isDesktop) {
                        setIsMobileOverlayVisible(true)
                      }
                    }}
                  />
                )}
              </>
            )}
            <InPageValidationErrors
              definition={definition}
              offline_id={entity?.offline_id}
              step={identifierCurrentPage}
            />

            <ScrollView>
              {currentPage == 1 && (
                <>
                  {!!definition.date_significant && (
                    <EntityField
                      mode={mode}
                      entity={entity}
                      name={'due_at'}
                      errorMessage={''}
                      field={{
                        title: 'Due Date',
                        field_data: { property: 'due_at', type: 'date-picker' }
                      }}
                      fileAppended={fileAppended}
                      fieldChanged={fieldChanged}
                      key={'due date'}
                    />
                  )}
                  {isGlobalAdmin && (
                    <EntityField
                      mode={mode}
                      entity={entity}
                      name={'tenant'}
                      errorMessage={''}
                      field={{
                        title: 'Tenant',
                        field_data: {
                          property: 'tenant_id',
                          type: 'select-tenant'
                        }
                      }}
                      adminLabel="Global Admin Control"
                      fieldChanged={fieldChanged}
                      key={'tenant'}
                    />
                  )}

                  {(!!_.get(definition, 'object_data.show_assignee', false) ||
                    definition.is_task) && (
                    <EntityFieldSelectAssignee
                      mode={mode}
                      entity={entity}
                      name={'assignees'}
                      errorMessage={''}
                      field={{
                        title: 'Assigned To',
                        field_data: {
                          property: 'assignees',
                          type: 'select-list-multiple',
                          params: {}
                        }
                      }} /* params: {sourceName: ['personnel', 'department', 'tenant']}  source name is filled by EntityFieldSelectAssignee being clever */
                      fieldChanged={(field, fieldValue, isUserInitiated) =>
                        fieldChanged(field, fieldValue, isUserInitiated)
                      }
                      key={'assigned to'}
                    />
                  )}
                </>
              )}
              <View
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
              >
                {columnSets.map((columnSet, index) => (
                  <View key={index} style={style}>
                    {columnSet.map((field, index) => {
                      const errorMessage = _.get(
                        errors,
                        field.field_data.property,
                        []
                      ).join(',')

                      /* Making a static header rise above a
            group of fields that all share a common
            header. */
                      // if this field could have a static header

                      let renderStaticHeader = getRenderStaticHeader(
                        field,
                        index,
                        columnSet
                      )
                      // suppress this ones static header
                      /* / */

                      return (
                        <EntityField
                          fieldIndex={index}
                          mode={mode}
                          entity={entity}
                          definition={definition}
                          name={field.field_data.property}
                          dataAwaitingApproval={
                            entity?.data_requiring_approval?.[
                              field.field_data?.property
                            ]
                          }
                          pendingApprovals={pendingApprovals}
                          onApproveToggled={() => {
                            onApproveToggled(field.field_data?.property)
                          }}
                          renderStaticHeader={renderStaticHeader}
                          prepopulatedFieldValue={
                            prepopulatedFields
                              ? prepopulatedFields[field.field_data.property]
                              : undefined
                          }
                          fieldVisibilityRules={fieldVisibilityRules}
                          errorMessage={errorMessage}
                          field={field}
                          fileAppended={fileAppended}
                          fieldChanged={fieldChanged}
                          key={index + '-' + field.id}
                        />
                      )
                    })}
                  </View>
                ))}
                {!isDesktop && (
                  <ValidationAndButtonBar
                    definition={definition}
                    fieldChanged={fieldChanged}
                    offline_id={entity?.offline_id}
                    identifierCurrentPage={identifierCurrentPage}
                    shouldPaginate={shouldPaginate}
                    hasNextStep={hasNextStep}
                    currentPage={currentPage}
                    pageVisibilityRules={pageVisibilityRules}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    availablePages={availablePages}
                    requiresCaptchaFilled={requiresCaptchaFilled}
                    isWizard={isWizard}
                    hideButtons={hideButtons}
                    saveButtonLabel={saveButtonLabel}
                    onSaveEntityPressed={onSaveEntityPressed}
                    onLastPageOfWizard={onLastPageOfWizard}
                    mode={mode}
                    loading={loading}
                  >
                    {children}
                  </ValidationAndButtonBar>
                )}
              </View>
            </ScrollView>
            {isDesktop && (
              <ValidationAndButtonBar
                definition={definition}
                fieldChanged={fieldChanged}
                offline_id={entity?.offline_id}
                identifierCurrentPage={identifierCurrentPage}
                shouldPaginate={shouldPaginate}
                hasNextStep={hasNextStep}
                currentPage={currentPage}
                pageVisibilityRules={pageVisibilityRules}
                visibleFields={visibleFields}
                prevPage={prevPage}
                nextPage={nextPage}
                availablePages={availablePages}
                requiresCaptchaFilled={requiresCaptchaFilled}
                isWizard={isWizard}
                hideButtons={hideButtons}
                saveButtonLabel={saveButtonLabel}
                onSaveEntityPressed={onSaveEntityPressed}
                onLastPageOfWizard={onLastPageOfWizard}
                mode={mode}
                loading={loading}
              >
                {children}
              </ValidationAndButtonBar>
            )}
          </View>
        </CrossFieldReferencesContext.Provider>
      </CrossFieldRenderAsNullContext.Provider>
    </>
  )
}

export default withTheme(EntityEdit)
